import Cookies from "universal-cookie";
import { hexToFullColour } from "../utils/Utils";

const cookies = new Cookies();

const cookie = {
  save: (key, value) => cookies.set(key, value),
  load: (key) => cookies.get(key.toString()),
};

import {
  SET_BACKGROUND_COLOUR,
  SET_RED_COLOUR,
  SET_GREEN_COLOUR,
  SET_RED_LUMINANCE,
  SET_GREEN_LUMINANCE,
  SET_TEST_AREA_DIMENSIONS,
  SET_SHAPE_DIMENSIONS,
  SET_LINE_THICKNESS,
  SET_CROSS_THICKNESS,
  SET_CROSS_LENGTH,
  SET_SPEED,
  SET_ASPECT_RATIO,
  SET_ROTATION,
  SET_REFERENCE_COLOUR,
  SET_ADJUSTMENT_MODE,
  SET_TEXT_CONTENT,
  SET_TEXT_RANDOMISED,
  SET_TEXT_ACTIVE_TEXT,
  SET_TEXT_WIDTH,
  SET_TEXT_RED_OPACITY,
  SET_TEXT_GREEN_OPACITY,
  SET_TEXT_FONT_SIZE,
  SET_TEXT_TEST_TYPE,
  SET_TEXT_RED_WORD_COLOUR,
  SET_TEXT_GREEN_WORD_COLOUR,
  SET_TEXT_RED_WORD_OPACITY,
  SET_TEXT_GREEN_WORD_OPACITY,
  SET_ACTIVE_PROFILE,
  ADD_PROFILE,
  SET_NEAR_ACUITY_INITIAL_SIZE,
  SET_NEAR_ACUITY_ITERATIONS,
  SET_NEAR_ACUITY_DIVIDER,
  SET_WORD_TIMER_FONT_SIZE,
  SET_WORD_TIMER_TEST_LENGTH,
  SET_WORD_TIMER_ACTIVE_TEXT,
  SET_NEAR_FAR_FONT_SIZE,
  SET_NEAR_FAR_FONT_FAMILY,
  SET_NEAR_FAR_FONT_STRETCH,
  SET_NEAR_FAR_FONT_STYLE,
  SET_SITE,
  SET_USER,
  SET_BOOK_MODE,
} from "../actions/actions";
/*
type FullColour = {
  r: number,
  g: number,
  b: number,
  a: number,
};

type Dimension = [number, number];

type IProfile = {
  lineThickness: number,
  crossLength: number,
  crossThickness: number,
  dimensions: {
    shape: Dimension,
    testArea: Dimension,
  },
  colours: {
    red: FullColour,
    redLuminance: number,
    green: FullColour,
    greenLuminance: number,
    background: FullColour,
  },
  wordTimer: {
    testLength: number,
    fontSize: number,
    activeText: number,
  },
  nearAcuity: {
    initialSize: number,
    divider: number,
    iterations: number,
  },
  nearFarRock: {
    fontSize: number,
    fontFamily: string,
    fontStretch: number,
    fontStyle: string, // italic, oblique
  },
  text: {
    fontSize: number,
    redOpacity: number,
    greenOpacity: number,
    width: number,
    testType: "by_word",
    redWordColour: FullColour,
    greenWordColour: FullColour,
    redWordOpacity: number,
    greenWordOpacity: number,
    activeText: number,
    randomised: boolean,
    bookMode: null | { bookId: number, section: number },
  },
  aspectRatio: number,
  speed: number,
  rotation: number,
  referenceColour: "red" | "green",
  adjustmentMode: "by_edge",
};
*/
const applyStateFromCookie = (state, name, defaultVal = state[name]) => {
  const v = cookies.get(name);
  state[name] = v ? v : defaultVal;
};

//applyProfileStateFromCookie(state, "activeProfile", "_");

const applyProfileStateFromCookie = (state, name, defaultVal) => {
  const v = cookies.get(name);
  state.profiles[name] = v ? v : defaultVal;
};

export const getInitialState = () => {
  const state = {
    activeProfile: "_",
    site: "",
    user: { xxx: "banana" },
    profiles: {
      _: {
        lineThickness: 16,
        crossLength: 12,
        crossThickness: 2,
        dimensions: {
          shape: [200, 200],
          testArea: [600, 600],
        },
        colours: {
          red: { r: 255, g: 0, b: 31, a: 1 },
          redLuminance: 100,
          green: { r: 13, g: 245, b: 0, a: 1 },
          greenLuminance: 100,
          background: { r: 231, g: 205, b: 0, a: 1 },
        },
        wordTimer: {
          testLength: 60,
          fontSize: 6,
          activeText: 0,
        },
        nearAcuity: {
          initialSize: 7,
          divider: 0.8,
          iterations: 13,
        },
        nearFarRock: {
          fontSize: 3,
          fontFamily: "cursive",
          fontStretch: 100,
          fontStyle: "normal", // italic, oblique
        },
        text: {
          fontSize: 2,
          redOpacity: 0.5,
          greenOpacity: 0.8,
          width: 20,
          testType: "by_word",
          redWordColour: { r: 255, g: 0, b: 0, a: 1 },
          greenWordColour: { r: 0, g: 255, b: 0, a: 1 },
          redWordOpacity: 1.0,
          greenWordOpacity: 1.0,
          activeText: 0,
          randomised: true,
          bookMode: { bookId: 0, section: 0 },
        },
        aspectRatio: 1.0,
        speed: 400,
        rotation: 0,
        referenceColour: "red",
        adjustmentMode: "by_edge",
      },
    },
    textContent: [
      `is it my me no see we go up of on he `,
      `I go come went up you day was are the of we this dog me big she and my see on mum it at no yes for a dad can he am all is cat get said to in go come went up I you was are the of we me big she and my see this dog on mum it at no can in he am yes for a dad all is cat get said to I go come went up you day was are the of we this dog me big she and my see on mum it at no yes for a dad can he am all is cat get said to in go come went up I you was are the of we me big she and my see this dog on mum it at no can in he am yes for a dad all is cat get said to I go come went up you day was are the of we dog this me big she and my see on it at no mum yes for a dad can he am all is cat get said to in go come up I you was are the of we me big she and my see this dog on mum it at no can in he am yes for a dad all is cat get said to I go come went up you day was are the of we this dog me big she and my see on mum it at no yes for a dad can he am all is cat get said to in go come went up I you was are the of we me big she and my see this dog on mum it at no can in he am yes for a dad all is cat get said to`,
      `old cat boy cow big but pet pig not one was yes the six sat man run hot let fun fat box all two ten she bat ram cat sat can wet den dad bus red jam net peg hit dig win bid map wag old cat boy cow big but pet pig not one was yes the six sat man run hot let fun fat box all two ten she bat ram cat sat can wet den dad bus red jam net peg hit dig win bid map wag dog old cat boy cow big but pet pig not one was yes the six sat man run hot let fun fat box all two ten she bat ram cat sat can wet den dad bus red jam net peg hit dig win bid map wag dog old cat boy cow big but pet pig not one was yes the six sat man run hot let fun fat box all two ten she bat ram cat sat can wet den dad bus red jam net peg hit dig win bid map wag dog old cat boy cow big but pet pig not one was yes the six sat man run hot let fun fat box all two ten she bat ram cat sat can wet den dad bus red jam net peg hit dig win bid map wag dog`,
      `all rat toy hat six got car cat hot ran pig sun get fun box did dog pan hen pet man bed but his cow red run see sat ball big mum not the him can yes was top old may and ten sit dad let one had two you out`,
      `fix met stay ship lost feet any food milk snow sick fire find hard page tree wash much gave bird both grow told most four pick keep king hold rock wind coat nine what rest rain hole that miss sing love fish hand flag take pull jump full best wood sang help full song base back nail talk bike only goat took gold next feed tell deep late must week over need road moon long door post mine move game drop farm give side pony city line seen gone part doll foot more room each your warm hill meet`,
      `carry lady plan teeth past great kind west mean smoke point hair paper done river trade half born table says order front kids list dark water place sugar form often care shall poor high rich hour heat race cool east real salt grew below pair given round song camp given wool fight free beach third wait head plane trip wild near street busy cage sound year under past life coal sight team also felt news care turn taken barn knife class short learn mind  bread cave ready uncle mouth stairs watch pinch kept later sled reach wrong south hurry music alone face study throw their draw world began month even sport sixth broke does earth noise above mouse tenth penny space which eight stood`,
      `ninth office heavy known fifth built scare since circus visit raise parent across picnic sudden laugh radio enjoy bottom during middle whose prize else minute reason harden board pencil turtle wrote broken peanut toward slowly between chicken return wear against idea stories young build second finish group except ocean cities doctor held tired really suit until police field cloth whole quite enough arrive planet charge yell sure future arrive`,
    ],
  };

  applyProfileStateFromCookie(state, "activeProfile", "_");
  applyStateFromCookie(state, "profiles", state.profiles);
  applyStateFromCookie(state, "textContent", state.textContent);

  return state;
};

export default function rootReducer(state, action) {
  //  console.log(`Reduce ${JSON.stringify(action)}`);
  if (state === undefined) return;
  let profile;
  switch (action.type) {
    case SET_TEXT_CONTENT:
      // eslint-disable-next-line no-case-declarations
      const textContent = [...state.textContent];
      textContent[action.value.activeText] = action.value.content;
      state = { ...state, textContent };

      cookie.save("textContent", state.textContent);
      break;
    case SET_TEXT_RANDOMISED:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          randomised: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_ACTIVE_TEXT:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          activeText: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      //      state = { ...state, text: { ...state.text, activeText: action.value } };
      //      cookie.set("textActiveText", state.text.activeText);
      break;
    case SET_TEXT_WIDTH:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          width: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);

      //     state = { ...state, text: { ...state.text, width: action.value } };
      //     cookie.set("textWidth", state.text.width);
      break;
    case SET_TEXT_RED_OPACITY:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          redOpacity: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      //      state = { ...state, text: { ...state.text, redOpacity: action.value } };
      //     cookie.set("textRedOpacity", state.text.redOpacity);
      break;
    case SET_TEXT_GREEN_OPACITY:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          greenOpacity: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      //      state = { ...state, text: { ...state.text, greenOpacity: action.value } };
      //cookie.set("textGreenOpacity", state.text.greenOpacity);
      break;
    case SET_TEXT_FONT_SIZE:
      //      state = { ...state, text: { ...state.text, fontSize: action.value } };
      //      cookie.set("textFontSize", state.text.fontSize);
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          fontSize: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_TEST_TYPE:
      //      state = { ...state, text: { ...state.text, testType: action.value } };
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          testType: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_RED_WORD_COLOUR:
      //     state = { ...state, text: { ...state.text, redWordColour: hexToFullColour(action.value) } };
      //     cookie.set("textRedWordColour", state.text.redWordColour);

      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          redWordColour: hexToFullColour(action.value),
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_GREEN_WORD_COLOUR:
      //      state = { ...state, text: { ...state.text, greenWordColour: hexToFullColour(action.value) } };
      //      cookie.set("textGreenWordColour", state.text.greenWordColour);

      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          greenWordColour: hexToFullColour(action.value),
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_RED_WORD_OPACITY:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          redWordOpacity: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_TEXT_GREEN_WORD_OPACITY:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          greenWordOpacity: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_BOOK_MODE:
      profile = {
        ...state.profiles[state.activeProfile],
        text: {
          ...state.profiles[state.activeProfile].text,
          bookMode: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_BACKGROUND_COLOUR:
      profile = {
        ...state.profiles[state.activeProfile],
        colours: {
          ...state.profiles[state.activeProfile].colours,
          background: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_RED_COLOUR:
      profile = {
        ...state.profiles[state.activeProfile],
        colours: {
          ...state.profiles[state.activeProfile].colours,
          red: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_GREEN_COLOUR:
      profile = {
        ...state.profiles[state.activeProfile],
        colours: {
          ...state.profiles[state.activeProfile].colours,
          green: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_RED_LUMINANCE:
      profile = {
        ...state.profiles[state.activeProfile],
        colours: {
          ...state.profiles[state.activeProfile].colours,
          redLuminance: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_GREEN_LUMINANCE:
      profile = {
        ...state.profiles[state.activeProfile],
        colours: {
          ...state.profiles[state.activeProfile].colours,
          greenLuminance: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_TEST_AREA_DIMENSIONS:
      profile = {
        ...state.profiles[state.activeProfile],
        dimensions: {
          shape: state.profiles[state.activeProfile].dimensions.shape,
          testArea: [...action.value],
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_SHAPE_DIMENSIONS:
      profile = {
        ...state.profiles[state.activeProfile],
        dimensions: {
          testArea: state.profiles[state.activeProfile].dimensions.testArea,
          shape: [...action.value],
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      /*
         state = {
           ...state,
           dimensions: { ...state.dimensions, shape: [...action.value] },
         };
         cookie.set("dimensions", state.dimensions); */
      break;

    case SET_LINE_THICKNESS:
      profile = {
        ...state.profiles[state.activeProfile],
        lineThickness: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_CROSS_THICKNESS:
      profile = {
        ...state.profiles[state.activeProfile],
        crossThickness: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_CROSS_LENGTH:
      profile = {
        ...state.profiles[state.activeProfile],
        crossLength: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_SPEED:
      profile = { ...state.profiles[state.activeProfile], speed: action.value };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_ASPECT_RATIO:
      profile = {
        ...state.profiles[state.activeProfile],
        aspectRatio: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_ROTATION:
      profile = {
        ...state.profiles[state.activeProfile],
        rotation: isNaN(parseInt(action.value, 10))
          ? 0
          : parseInt(action.value, 10),
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_REFERENCE_COLOUR:
      profile = {
        ...state.profiles[state.activeProfile],
        referenceColour: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_ADJUSTMENT_MODE:
      profile = {
        ...state.profiles[state.activeProfile],
        adjustmentMode: action.value,
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_ACUITY_INITIAL_SIZE:
      profile = {
        ...state.profiles[state.activeProfile],
        nearAcuity: {
          ...state.profiles[state.activeProfile].nearAcuity,
          initialSize: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_ACUITY_ITERATIONS:
      profile = {
        ...state.profiles[state.activeProfile],
        nearAcuity: {
          ...state.profiles[state.activeProfile].nearAcuity,
          iterations: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_ACUITY_DIVIDER:
      profile = {
        ...state.profiles[state.activeProfile],
        nearAcuity: {
          ...state.profiles[state.activeProfile].nearAcuity,
          divider: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_WORD_TIMER_FONT_SIZE:
      profile = {
        ...state.profiles[state.activeProfile],
        wordTimer: {
          ...state.profiles[state.activeProfile].wordTimer,
          fontSize: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_WORD_TIMER_TEST_LENGTH:
      profile = {
        ...state.profiles[state.activeProfile],
        wordTimer: {
          ...state.profiles[state.activeProfile].wordTimer,
          testLength: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_WORD_TIMER_ACTIVE_TEXT:
      profile = {
        ...state.profiles[state.activeProfile],
        wordTimer: {
          ...state.profiles[state.activeProfile].wordTimer,
          activeText: parseInt(action.value, 10),
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_FAR_FONT_SIZE:
      profile = {
        ...state.profiles[state.activeProfile],
        nearFarRock: {
          ...state.profiles[state.activeProfile].nearFarRock,
          fontSize: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_FAR_FONT_FAMILY:
      profile = {
        ...state.profiles[state.activeProfile],
        nearFarRock: {
          ...state.profiles[state.activeProfile].nearFarRock,
          fontFamily: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_FAR_FONT_STRETCH:
      profile = {
        ...state.profiles[state.activeProfile],
        nearFarRock: {
          ...state.profiles[state.activeProfile].nearFarRock,
          fontStretch: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_NEAR_FAR_FONT_STYLE:
      profile = {
        ...state.profiles[state.activeProfile],
        nearFarRock: {
          ...state.profiles[state.activeProfile].nearFarRock,
          fontStyle: action.value,
        },
      };
      state = {
        ...state,
        profiles: { ...state.profiles, [state.activeProfile]: profile },
      };
      cookie.save("profiles", state.profiles);
      break;
    case SET_ACTIVE_PROFILE:
      state = {
        ...state,
        activeProfile: action.value,
      };
      cookie.save("activeProfile", state.activeProfile);
      break;

    case ADD_PROFILE:
      state = {
        ...state,
        profiles: {
          ...state.profiles,
          [action.value]: { ...state.profiles[state.activeProfile] },
        },
        activeProfile: action.value,
      };
      cookie.save("profiles", state.profiles);
      break;

    case SET_SITE:
      state = { ...state, site: action.value };
      break;

    case SET_USER:
      state = { ...state, user: { ...action.value } };
      break;

    default:
      return state;
  }
  return state;
}
