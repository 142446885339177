import React from "react";
import ReactColourPicker from "react-pick-color";
import { Button } from "reactstrap";

function ColourPicker(props) {
  const { pickerOn, initialColour, setPickedColour, closeColourPicker } = props;
  const backupColour = initialColour;
  const doCancel = () => {
    console.log("DO CANCEL");
    setPickedColour({ rgb: backupColour });
    closeColourPicker();
  };
  const popover = {
    backgroundColor: "white",
    padding: "4",
  };
  return (
    <>
      {pickerOn ? (
        <>
          <div style={popover}>
            <ReactColourPicker
              color={initialColour}
              onChange={setPickedColour}
              hideAlpha={true}
              hideInputs={true}
            />
            <div style={{ margin: 4, float: "right" }}>
              <Button size="sm" color="primary" onClick={doCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ColourPicker;
