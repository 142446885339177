import React from "react";
import "./BuilderPath.css";
import { Nav, Navbar } from "reactstrap";
import Settings from "../Settings/Settings";
import NearAcuity from "../Tests/NearAcuity";
import HalfRect from "../Tests/HalfRect";
import ImageSize from "../Tests/ImageSize";
import FixationDisparity from "../Tests/FixationDisparity";
import FourDots from "../Tests/FourDots";
import NearFarRock from "../Tests/NearFarRock";
import TextContainer from "../Tests/TextContainer";
import WordTimer from "../Tests/WordTimer";
import TestContainer from "../Tests/TestContainer";
import Report from "../Report";

const options = {
  Settings: "Configuration",
  "Near acuity": "Near acuity",
  /*  ImageSize: "Image size (Aniseikonia)", */
  "Half rect": "Image size half square",
  "Fixation disparity": "Fixation disparity",
  "Four dots": "Four dots",
  "Near far rock": "Near far rock",
  Text: "Text",
  "Word timer": "Word timer",
  Test: "Test",
  Report: "Report",
};

export default function BuilderPath(props) {
  const { setCurrentTest } = props;
  const renderSwitch = () => {
    switch (props.test) {
      case 0:
        return (
          <Settings
            profiles
            configuration
            testsettings
            nearacuity
            wordtimer
            nearfar
            text
          />
        );
      case 1:
        return <NearAcuity />;
      /*      case 2:
        return <ImageSize />; */
      case 2:
        return <HalfRect />;
      case 3:
        return <FixationDisparity />;
      case 4:
        return <FourDots />;
      case 5:
        return <NearFarRock />;
      case 6:
        return <TextContainer />;
      case 7:
        return <WordTimer />;
      case 8:
        return <TestContainer />;
      case 9:
        return <Report />;
      default:
        return "Thats all folks";
    }
  };

  return (
    <div>
      <Navbar color="faded" light>
        <Nav navbar>
          <ol className="menuList">
            {Object.keys(options).map((option, idx) => (
              <li
                onClick={() => setCurrentTest(idx)}
                className={`${
                  idx === props.test ? "builderItemActive" : "builderItem"
                }`}
                key={option}
              >
                {options[option]}
              </li>
            ))}
          </ol>
        </Nav>
      </Navbar>
      <div style={{ backgroundColour: "#ff8080" }}>{renderSwitch()}</div>
    </div>
  );
}
