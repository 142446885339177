import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./NearAcuity.css";

const CHARS_PER_LINE = 16;

function NearAcuity(props) {
  const { textContent, initialSize, divider, iterations } = props;
  const wordSalad = textContent.trim().split(/\W+/);

  const rows = [];
  const fontSizes = [];
  let badWords = {};

  const getTestWord = (currentWord) => {
    let allowableWords = wordSalad.filter((w) => badWords[w] === undefined);
    if (allowableWords.length === 0) {
      badWords = {};
      allowableWords = [...wordSalad];
    }
    const w =
      allowableWords.length > 0
        ? allowableWords[Math.floor(Math.random() * allowableWords.length)]
        : "No more words!";
    badWords[w] = true;
    return w;
  };

  let fontSize = initialSize;
  for (let i = 0; i < iterations; i++) {
    fontSizes.push(fontSize);
    fontSize *= divider;
    let wordString = getTestWord();
    while (wordString.length < CHARS_PER_LINE) {
      wordString += " " + getTestWord();
    }
    rows.push(wordString);
  }

  return (
    <>
      <h3 className="text-center my-4">Near acuity</h3>
      <Row className="justify-content-center">
        <Col xs="auto">
          {rows.map((r, idx) => (
            <Row key={idx}>
              <div
                className="fontSizeAnnotation"
                style={{ lineHeight: `${fontSizes[idx]}em` }}
              >
                <span className="fontText">{fontSizes[idx].toFixed(2)}</span>
              </div>
              <span
                className="text-center"
                style={{ fontSize: `${fontSizes[idx]}em` }}
              >
                {r}
              </span>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => ({
  initialSize: state.profiles[state.activeProfile].nearAcuity.initialSize,
  divider: state.profiles[state.activeProfile].nearAcuity.divider,
  iterations: state.profiles[state.activeProfile].nearAcuity.iterations,
  textContent:
    state.textContent.length >
    parseInt(state.profiles[state.activeProfile].text.activeText, 10)
      ? state.textContent[state.profiles[state.activeProfile].text.activeText]
      : "",
});

export default connect(mapStateToProps)(NearAcuity);
