import React, { useRef, useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import "./NearFarRock.css";

const BORDER_WIDTH = 32;
const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

// eslint-disable-next-line react/display-name
const JustTheCanvas = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    className={props.printSize === "small" ? "printThingSmall" : "printThing"}
  >
    <canvas
      className="App-canvas"
      ref={props.canvasRef}
      width={
        props.scaleAdjust * (parseInt(props.testAreaDimensions[0], 10) + 1)
      }
      height={
        props.scaleAdjust * (parseInt(props.testAreaDimensions[1], 10) + 1)
      }
    ></canvas>
  </div>
));

function NearFarRock(props) {
  const { testAreaDimensions, fontSize, fontFamily, fontStretch, fontStyle } =
    props;
  const canvasRef = useRef(null);
  const componentRef = useRef(null);
  const [caseing, setCaseing] = useState("mixed");
  const [scaleAdjust, setScaleAdjust] = useState(1.5);
  const [printSize, setPrintSize] = useState("normal");

  const setSaneScaleAdjust = (value) => {
    let scale = parseFloat(value);
    if (scale < 0.01) {
      scale = 0.01;
    }
    if (scale > 3) {
      scale = 3;
    }
    setScaleAdjust(scale);
  };

  const drawToContext = (ctx, testAreaDimensions, scaleAdjust) => {
    ctx.clearRect(
      0,
      0,
      testAreaDimensions[0] * scaleAdjust,
      testAreaDimensions[1] * scaleAdjust
    );
    ctx.lineWidth = BORDER_WIDTH * scaleAdjust;
    ctx.fillStyle = "black";

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(testAreaDimensions[0] * scaleAdjust, 0);
    ctx.lineTo(
      testAreaDimensions[0] * scaleAdjust,
      testAreaDimensions[1] * scaleAdjust
    );
    ctx.lineTo(0, testAreaDimensions[0] * scaleAdjust);
    ctx.closePath();
    ctx.stroke();

    ctx.textAlign = "center";
    const fs = `${fontStyle} ${fontStretch} ${
      fontSize * scaleAdjust
    }em ${fontFamily}`;
    ctx.font = fs;

    const xStep =
      ((testAreaDimensions[0] * scaleAdjust - BORDER_WIDTH * scaleAdjust) /
        10) *
      (9.5 / 10);
    const yStep =
      ((testAreaDimensions[1] * scaleAdjust - BORDER_WIDTH * scaleAdjust) /
        10) *
      (9.5 / 10);
    for (let x = 0; x < 10; x++) {
      for (let y = 0; y < 10; y++) {
        let char = CHARS[Math.floor(Math.random() * CHARS.length)];
        if (caseing === "upper") {
          char = char.toUpperCase();
        }
        if (caseing === "lower") {
          char = char.toLowerCase();
        }
        ctx.fillText(
          char,
          BORDER_WIDTH * scaleAdjust * 1.75 + x * xStep,
          BORDER_WIDTH * scaleAdjust * 2.25 + y * yStep
        );
      }
    }
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");
    drawToContext(ctx, testAreaDimensions, scaleAdjust);
  }, [scaleAdjust, testAreaDimensions, caseing]); // eslint-disable-line

  const setNextCaseing = () => {
    if (caseing === "mixed") {
      setCaseing("upper");
    } else if (caseing === "upper") {
      setCaseing("lower");
    } else {
      setCaseing("mixed");
    }
  };
  return (
    <>
      <Row className="justify-content-left">
        <Col xs="10">
          <h4 className="centreH4">Near far rock</h4>
        </Col>
      </Row>
      <Row>
        <Col xs="10">
          <JustTheCanvas
            ref={componentRef}
            canvasRef={canvasRef}
            testAreaDimensions={testAreaDimensions}
            scaleAdjust={scaleAdjust}
            printSize={printSize}
          />
        </Col>

        <Col xs="2">
          <div className="buttonRow">
            <ReactToPrint
              trigger={() => <Button>Print</Button>}
              content={() => componentRef.current}
            />
            &nbsp;
            <Button onClick={setNextCaseing}>
              {caseing === "upper" && "AZ"}
              {caseing === "lower" && "az"}
              {caseing === "mixed" && "Az"}
            </Button>
          </div>
          <div className="buttonRow">
            <Button
              onClick={() =>
                setPrintSize(printSize === "normal" ? "small" : "normal")
              }
            >
              {printSize === "normal" ? "Distance" : "Near"}
            </Button>
          </div>
          <div className="buttonRow">
            <Button onClick={() => setSaneScaleAdjust(scaleAdjust * 0.99)}>
              -
            </Button>
            &nbsp;
            <Button onClick={() => setSaneScaleAdjust(scaleAdjust * 1.01)}>
              +
            </Button>{" "}
            {scaleAdjust.toFixed(2)}
          </div>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  testAreaDimensions: [
    parseInt(state.profiles[state.activeProfile].dimensions.testArea[0], 10),
    parseInt(state.profiles[state.activeProfile].dimensions.testArea[1], 10),
  ],
  fontSize: state.profiles[state.activeProfile].nearFarRock.fontSize,
  fontFamily: state.profiles[state.activeProfile].nearFarRock.fontFamily,
  fontStretch: state.profiles[state.activeProfile].nearFarRock.fontStretch,
  fontStyle: state.profiles[state.activeProfile].nearFarRock.fontStyle,
});

export default connect(mapStateToProps)(NearFarRock);
