import React, { useRef, useEffect } from "react";
import { fullColourHex } from "../utils/Utils";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./FourDots.css";

function FourDots(props) {
  const { testAreaDimensions, redColour, greenColour } = props;
  const canvasRef = useRef(null);

  const drawBlob = (ctx, x, y, radius, colour) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = colour;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = colour;
    ctx.stroke();
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);
    const blobSize = testAreaDimensions[0] / 24;
    drawBlob(
      ctx,
      testAreaDimensions[0] / 4,
      testAreaDimensions[1] / 2,
      blobSize,
      greenColour
    );
    drawBlob(
      ctx,
      testAreaDimensions[0] * 0.75,
      testAreaDimensions[1] / 2,
      blobSize,
      greenColour
    );
    drawBlob(
      ctx,
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] * 0.33,
      blobSize,
      redColour
    );
    drawBlob(
      ctx,
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] * 0.66,
      blobSize,
      "white"
    );
  });

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <h3 className="my-4 text-center">Four dots</h3>
        <canvas
          className="App-canvas"
          ref={canvasRef}
          width={testAreaDimensions[0] + 1}
          height={testAreaDimensions[1] + 1}
        ></canvas>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  redColour: fullColourHex(state.profiles[state.activeProfile].colours.red),
  greenColour: fullColourHex(state.profiles[state.activeProfile].colours.green),
  testAreaDimensions: [
    parseInt(state.profiles[state.activeProfile].dimensions.testArea[0], 10),
    parseInt(state.profiles[state.activeProfile].dimensions.testArea[1], 10),
  ],
});

export default connect(mapStateToProps)(FourDots);
