import React, { useState } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

export default function RedGreenSplash(props) {
  const { doContinue } = props;
  const [hasAgreed, setHasAgreed] = useState(false);

  return (
    <Container fluid>
      <Row className="justify-content-center mt-4">
        <Col xs="auto">
          <h2>
            <span style={{ color: "red" }}>Red</span>
            <span style={{ color: "green" }}>Green</span>Reader
          </h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="auto">
          <pre>Terms and conditions placeholder</pre>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="auto">
          <p>
            This service should only be used by or under the supervision of an
            eyecare professional, I confirm that I am a suitably qualified and
            licensed eye professional (Optometrist Ophthalmologist or
            Orthoptist)
          </p>
          <p>
            Do not use this app if you have an eye turn, often called squint or
            strabismus unless you have been expressly advised by an Eyecare
            professional (Optometrist, Orthoptist or Ophthalmologist) that it is
            safe to do so.
          </p>
          <p>
            If you experience double vision (also called diplopia) stop using
            the app and consult and eyecare professional.
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                value="hasAgreed"
                onChange={() => setHasAgreed(!hasAgreed)}
              />{" "}
              I agree to these conditions
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto">
          <Button color="primary" disabled={!hasAgreed} onClick={doContinue}>
            Submit
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
