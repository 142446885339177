import React, { useRef, useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./Distortion.css";

const DISTORTION_ARC = 0.08;

const drawDistortedCircle = (ctx, x, y, radius, colour, distortion) => {
    /*   ctx.beginPath();

           ctx.arc(x, y, radius, 0, (2 * Math.PI), false);

       ctx.lineWidth = 1;
       ctx.strokeStyle = colour;
       ctx.stroke();
   */
    ctx.beginPath();
    ctx.lineWidth = 8;
    ctx.strokeStyle = colour;


    if (distortion) {
        const distortionAngle = (Math.random() * 1.95 * Math.PI) + (0.05 * Math.PI);

        ctx.arc(x, y, radius, 0, distortionAngle - (DISTORTION_ARC * Math.PI), false);
        ctx.stroke();

        ctx.beginPath();
        let sin = Math.sin(distortionAngle + (DISTORTION_ARC * Math.PI));
        let cos = Math.cos(distortionAngle + (DISTORTION_ARC * Math.PI));
        let xoffs = radius * cos;
        let yoffs = radius * sin;
        ctx.moveTo(xoffs + x, yoffs + y);

        sin = Math.sin(distortionAngle);
        cos = Math.cos(distortionAngle);
        xoffs = Math.floor((radius + distortion) * cos);
        yoffs = Math.floor((radius + distortion) * sin);
        ctx.lineTo(xoffs + x, yoffs + y);

        sin = Math.sin(distortionAngle - (DISTORTION_ARC * Math.PI));
        cos = Math.cos(distortionAngle - (DISTORTION_ARC * Math.PI));
        xoffs = radius * cos;
        yoffs = radius * sin;
        ctx.lineTo(xoffs + x, yoffs + y);
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(x, y, radius, distortionAngle + (DISTORTION_ARC * Math.PI), 2 * Math.PI, false);

        ctx.stroke();
    } else {
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        ctx.stroke();
    }

    /*
        const sin = Math.sin(angle * (Math.PI / 180));
        const cos = Math.cos(angle * (Math.PI / 180));
        const xoffs = Math.floor((radius + distortionEffect) * sin);
        const yoffs = Math.floor((-radius + distortionEffect) * cos + 1);
        if (angle === 0) {
            ctx.moveTo(x + xoffs, y + yoffs);
        } else {
            ctx.lineTo(x + xoffs, y + yoffs);
        } */
    /*
        for (let angle = 0; angle < 360; angle += 2) {
            let distortionEffect = 0;
            let distortionRange = Math.abs(distortionAngle - angle);
            if (distortionRange >= 340 || distortionRange <= 20) {
                console.log(`Distortion range ${distortionRange} ${angle}`);
            }
            if (distortionRange > 180) {
                distortionRange = 360 - distortionRange;
            }
            if (distortionRange >= 340 || distortionRange <= 20) {
                console.log(`Distortion range [x] ${distortionRange} ${angle}`);
            }
            if (distortionRange < 20) {
                distortionEffect = ((20 - distortionRange) / 20) * distortion;
                console.log(`Distortion range [x] ${distortionRange} ${angle}`);
            }
            const sin = Math.sin(angle * (Math.PI / 180));
            const cos = Math.cos(angle * (Math.PI / 180));
            const xoffs = Math.floor((radius + distortionEffect) * sin);
            const yoffs = Math.floor((-radius + distortionEffect) * cos + 1);
            if (angle === 0) {
                ctx.moveTo(x + xoffs, y + yoffs);
            } else {
                ctx.lineTo(x + xoffs, y + yoffs);
            }
        } */

}

function Distortion(props) {
    const { testAreaDimensions } = props;
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext("2d");
        const distortion = Math.random() > 0.5 ? 1 : 0;
        if (distortion) {
            drawDistortedCircle(ctx, testAreaDimensions[0] * .25, testAreaDimensions[1] / 2, testAreaDimensions[0] / 6, 'black', 4);
            drawDistortedCircle(ctx, testAreaDimensions[0] * .75, testAreaDimensions[1] / 2, testAreaDimensions[0] / 6, 'black', 0);
        } else {
            drawDistortedCircle(ctx, testAreaDimensions[0] * .25, testAreaDimensions[1] / 2, testAreaDimensions[0] / 6, 'black', 0);
            drawDistortedCircle(ctx, testAreaDimensions[0] * .75, testAreaDimensions[1] / 2, testAreaDimensions[0] / 6, 'black', 4);
        }

    });

    return (<Row className="justify-content-center">
        <Col xs="auto">
            <h4>Distortion test</h4>
            <canvas
                className="App-canvas"
                ref={canvasRef}
                width={testAreaDimensions[0] + 1}
                height={testAreaDimensions[1] + 1}
            ></canvas>
        </Col></Row>)
}

const mapStateToProps = (state) => ({
    testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea.map(x => parseInt(x, 10))
});

export default connect(mapStateToProps)(Distortion);