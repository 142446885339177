import React from "react";
import { connect } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import {
    setNearAcuityInitialSize, setNearAcuityIterations, setNearAcuityDivider
} from "../actions/actions";

import "./NearAcuitySettings.css";

function NearAcuitySettings(props) {
    const {
        initialSize, divider, iterations,
        setInitialSize, setDivider, setIterations
    } = props;
    return (
        <div className="profiles">
            <h4>Near acuity settings</h4>
            <Form>
                <FormGroup row>
                    <Label for="initialSize" sm={4}>Initial Size</Label>
                    <Col sm={4}>
                        <Input name="initialSize" id="initialSize" value={initialSize} placeholder="initial size" onChange={e => setInitialSize(e.target.value)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="divider" sm={4}>Reduction ratio</Label>
                    <Col sm={4}>
                        <Input name="divider" id="divider" value={divider} placeholder="reduction ratio" onChange={e => setDivider(e.target.value)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="iterations" sm={4}>Test rows</Label>
                    <Col sm={4}>
                        <Input name="iterations" id="iterations" value={iterations} placeholder="test rows" onChange={e => setIterations(e.target.value)} />
                    </Col>
                </FormGroup>
            </Form>
        </div >
    );
}
const mapStateToProps = state => ({
    initialSize: state.profiles[state.activeProfile].nearAcuity.initialSize,
    divider: state.profiles[state.activeProfile].nearAcuity.divider,
    iterations: state.profiles[state.activeProfile].nearAcuity.iterations,
});
const mapDispatchToProps = (dispatch, value) => ({
    setInitialSize: (value) => dispatch(setNearAcuityInitialSize(value)),
    setIterations: (value) => dispatch(setNearAcuityIterations(value)),
    setDivider: (value) => dispatch(setNearAcuityDivider(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(NearAcuitySettings);