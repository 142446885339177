import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMaximize,
  faWindowRestore,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./WordTimer.css";
import WordTimerSettings from "../Settings/WordTimerSettings";
import Help from "../Help";
import { fullColourHex } from "../utils/Utils";
import FullSizer from "../Components/FullSizer/FullSizer";

function TestWord({ colour, opacity, word }) {
  return <span style={{ color: colour, opacity: opacity }}>{word}</span>;
}

function WordTimer(props) {
  const [showConfig, setShowConfig] = useState(false);

  const redColour = useSelector((state) =>
    fullColourHex(state.profiles[state.activeProfile].colours.red)
  );
  const greenColour = useSelector((state) =>
    fullColourHex(state.profiles[state.activeProfile].colours.green)
  );
  const testLength = useSelector(
    (state) => state.profiles[state.activeProfile].wordTimer.testLength
  );
  const textFontSize = useSelector(
    (state) => state.profiles[state.activeProfile].wordTimer.fontSize
  );
  const textRedOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.redOpacity
  );
  const textGreenOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.greenOpacity
  );
  const textContent = useSelector((state) =>
    state.textContent.length >
    parseInt(state.profiles[state.activeProfile].text.activeText, 10)
      ? state.textContent[state.profiles[state.activeProfile].text.activeText]
      : ""
  );
  const mainRef = useRef(null);
  const intervalTagRef = useRef(null);
  // const [started, setStarted] = useState(false);
  const [testTimer, setTestTimer] = useState(0);
  const [intervalTag, setIntervalTag] = useState(null);
  const wordSalad = textContent.trim().split(/\W+/);
  const [badWords, setBadWords] = useState({});
  const [wordsFound, setWordsFound] = useState(null);
  const [word, setWord] = useState(null);

  const toggle = () => setShowConfig(!showConfig);

  useEffect(() => {
    if (mainRef.current !== null && intervalTag) {
      mainRef.current.focus();
    }
  }, [intervalTag]);

  useEffect(() => {
    return function cleanUp() {
      if (intervalTagRef.current) {
        clearInterval(intervalTagRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButtonText = () =>
    intervalTag ? "Click to stop" : "Click to start";

  const getTestWord = () => {
    let allowableWords = wordSalad.filter(
      (w) => w !== word && badWords[w] === undefined
    );
    if (allowableWords.length === 0) {
      allowableWords = [...wordSalad];
      setBadWords({});
    }
    const w =
      allowableWords.length > 0
        ? allowableWords[Math.floor(Math.random() * allowableWords.length)]
        : "No more words!";
    setWord(w);
    return w;
  };

  const updateTestTimer = () => {
    setTestTimer((prevTime) => {
      if (prevTime >= testLength + 5) {
        clearInterval(intervalTagRef.current);
        setIntervalTag(null);
        intervalTagRef.current = null;
      }
      if (prevTime === 0) {
        getTestWord();
      }
      return prevTime + 1;
    });
  };

  const flipStartState = () => {
    if (intervalTag === null) {
      setTestTimer(0);
      intervalTagRef.current = setInterval(updateTestTimer, 1000);
      setIntervalTag(intervalTagRef.current);
      setBadWords({});
      setWordsFound(0);
    } else {
      clearInterval(intervalTagRef.current);
      setIntervalTag(null);
      intervalTagRef.current = null;
    }
  };

  const confirm = () => {
    setBadWords({ ...badWords, [word]: true });
    setWordsFound(wordsFound + 1);
    getTestWord();
  };

  const skip = () => {
    setBadWords({ ...badWords, [word]: true });
    getTestWord();
  };

  const userKey = (e) => {
    e.preventDefault();
    if (e.key === " " && testTimer > 4 && intervalTag) {
      confirm();
    }
    if (e.key.toLowerCase() === "p" && testTimer > 4 && intervalTag) {
      skip();
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Row>
        <Col xs={4}></Col>
        <Col xs={4} className="justify-content-center mt-4">
          <h2 className="text-center">Word timer</h2>
        </Col>
        <Col xs={{ size: 2, order: 2, offset: 2 }}>
          <ButtonGroup>
            <Button color="primary" size="sm" onClick={toggle}>
              Show settings
            </Button>
            <Help whichTest="word timer" />
          </ButtonGroup>
        </Col>
      </Row>
      <FullSizer>
        <Row className="justify-content-center mt-4">
          <Col xs={12} className="text-center">
            <>
              <Button size="small" color="primary" onClick={flipStartState}>
                {getButtonText()}
              </Button>
            </>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12}>
            <Row>
              <Col xs="12" className="text-centre">
                <div tabIndex={0} ref={mainRef} onKeyDown={(e) => userKey(e)}>
                  {wordsFound !== null && intervalTag === null && (
                    <div className="text-center wordBackground">
                      <h4>Total words read: {wordsFound}</h4>
                    </div>
                  )}
                  <div
                    className="text-center wordBackground"
                    style={{ fontSize: `${textFontSize}em` }}
                  >
                    {intervalTag !== null && (
                      <>
                        {testTimer < 5 ? (
                          <div className="countDown">{5 - testTimer}</div>
                        ) : (
                          <TestWord
                            colour={
                              Object.keys(badWords).length & 1
                                ? redColour
                                : greenColour
                            }
                            opacity={
                              Object.keys(badWords).length & 1
                                ? textRedOpacity
                                : textGreenOpacity
                            }
                            word={word}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {intervalTag !== null && testTimer >= 5 && (
              <Row>
                <Col xs="12" className="text-center">
                  <Button
                    className="wordTimerActionButton"
                    color="success"
                    onClick={confirm}
                  >
                    <FontAwesomeIcon size="4x" icon={faCheckCircle} />
                  </Button>
                  <Button
                    className="wordTimerActionButton"
                    color="danger"
                    onClick={skip}
                  >
                    <FontAwesomeIcon size="4x" icon={faTimesCircle} />
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </FullSizer>
      <Modal isOpen={showConfig} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>Word timer settings</ModalHeader>
        <ModalBody>
          <WordTimerSettings />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default WordTimer;
