import React, { useRef, useEffect } from "react";
import { fullColourHex, rotateShape } from "../utils/Utils";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./FixationDisparity.css";

function FixationDisparity(props) {
  const { redColour, greenColour, testAreaDimensions } = props;
  const canvasRef = useRef(null);

  const cross = (width, height) => ({
    x: [
      width / 2,
      width / 2,
      height / 2,
      height / 2,
      width / 2,
      width / 2,
      -width / 2,
      -width / 2,
      -height / 2,
      -height / 2,
      -width / 2,
      -width / 2,
    ],
    y: [
      -height / 2,
      -width / 2,
      -width / 2,
      width / 2,
      width / 2,
      height / 2,
      height / 2,
      width / 2,
      width / 2,
      -width / 2,
      -width / 2,
      -height / 2,
    ],
  });
  const rect = (width, height) => ({
    x: [width / 2, width / 2, -width / 2, -width / 2],
    y: [-height / 2, height / 2, height / 2, -height / 2],
  });

  const drawShape = (ctx, shape, x, y, colour, rotation) => {
    const newShape = rotateShape(shape, rotation);
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.shadowBlur = 0;
    ctx.strokeStyle = colour;
    ctx.moveTo(newShape.x[0] + x, newShape.y[0] + y);
    let idx = 1;
    while (idx < newShape.x.length) {
      ctx.lineTo(newShape.x[idx] + x, newShape.y[idx] + y);
      idx++;
    }
    ctx.lineTo(newShape.x[0] + x, newShape.y[0] + y);
    ctx.fillStyle = colour;
    ctx.fill();
    ctx.stroke();
  };
  const drawCircle = (ctx, x, y, radius, lineWidth, colour) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = colour;
    ctx.stroke();
  };

  useEffect(() => {
    const CIRCLE_RADIUS = 18;
    const CIRCLE_THICKNESS = 10;
    const CROSS_HEIGHT = 50;
    const CROSS_WIDTH = 10;
    const RECT_HEIGHT = 60;
    const OUTLINE_RECT_WIDTH = 20;
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");

    ctx.beginPath();

    ctx.lineWidth = OUTLINE_RECT_WIDTH;
    ctx.strokeStyle = "black";
    ctx.rect(
      testAreaDimensions[0] * 0.55,
      testAreaDimensions[0] * 0.3,
      testAreaDimensions[0] * 0.4,
      testAreaDimensions[0] * 0.4
    );
    ctx.rect(
      testAreaDimensions[0] * 0.05,
      testAreaDimensions[0] * 0.3,
      testAreaDimensions[0] * 0.4,
      testAreaDimensions[0] * 0.4
    );
    ctx.stroke();

    drawCircle(
      ctx,
      testAreaDimensions[0] * 0.15,
      testAreaDimensions[1] / 2,
      CIRCLE_RADIUS,
      CIRCLE_THICKNESS,
      "black"
    );
    let shape = cross(CROSS_WIDTH, CROSS_HEIGHT);
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.25,
      testAreaDimensions[1] / 2,
      "black",
      45
    );
    drawCircle(
      ctx,
      testAreaDimensions[0] * 0.35,
      testAreaDimensions[1] / 2,
      CIRCLE_RADIUS,
      CIRCLE_THICKNESS,
      "black"
    );
    shape = rect(CROSS_WIDTH, CROSS_HEIGHT);
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.25,
      testAreaDimensions[1] * 0.4,
      redColour,
      0
    );
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.25,
      testAreaDimensions[1] * 0.6,
      greenColour,
      0
    );

    drawCircle(
      ctx,
      testAreaDimensions[0] * 0.75,
      testAreaDimensions[1] * 0.4,
      CIRCLE_RADIUS,
      CIRCLE_THICKNESS,
      "black"
    );
    shape = cross(CROSS_WIDTH, CROSS_HEIGHT);
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.75,
      testAreaDimensions[1] * 0.5,
      "black",
      45
    );
    drawCircle(
      ctx,
      testAreaDimensions[0] * 0.75,
      testAreaDimensions[1] * 0.6,
      CIRCLE_RADIUS,
      CIRCLE_THICKNESS,
      "black"
    );
    shape = rect(CROSS_WIDTH, RECT_HEIGHT);
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.85,
      testAreaDimensions[1] * 0.5,
      redColour,
      90
    );
    drawShape(
      ctx,
      shape,
      testAreaDimensions[0] * 0.65,
      testAreaDimensions[1] * 0.5,
      greenColour,
      90
    );
  });

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <h3 className="my-4 text-center">Fixation disparity</h3>
        <canvas
          className="App-canvas"
          ref={canvasRef}
          width={testAreaDimensions[0]}
          height={testAreaDimensions[1]}
        ></canvas>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  redColour: fullColourHex(state.profiles[state.activeProfile].colours.red),
  greenColour: fullColourHex(state.profiles[state.activeProfile].colours.green),
  shapeDimensions: state.profiles[state.activeProfile].dimensions.shape,
  testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea,
  aspectRatio: state.profiles[state.activeProfile].aspectRatio,
});

export default connect(mapStateToProps)(FixationDisparity);
