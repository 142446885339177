import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavItem,
} from "reactstrap";
import { logout, hello } from "./utils/Api";
import { setUser } from "./actions/actions";
import Cookies from "universal-cookie";
import BuilderPath from "./pathways/BuilderPath";
import DevPath from "./pathways/DevPath";
import ProdPath from "./pathways/ProdPath";
import RedGreenPath from "./pathways/RedGreenPath";
import Login from "./Login";

import "./Gateway.css";

export default function Gateway() {
  const cookie = new Cookies();
  const user = useSelector((store) => {
    //    console.log(`Get user from store ${JSON.stringify(store.user)}`);
    return store.user;
  });

  const dispatch = useDispatch();
  const [currentTest, setCurrentTest] = useState(0);
  const site = useSelector((state) => state.site) ?? "";
  const [token, setToken] = useState(null);
  const [pathway, setPathway] = useState("");

  useEffect(() => {
    const localToken = cookie.get("token");
    console.log(`Loading token ${localToken}`);
    setToken(localToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        const user = await hello();
        if (!user) {
          dispatch(setUser({}));
          console.log("Logging out");
          await logout();
          cookie.remove("token");
          setToken(null);
        } else {
          console.log("Got user from back end");
          dispatch(setUser(user));
        }
      };
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  useEffect(() => {
    console.log(`Got user change in component [${JSON.stringify(user)}]`);
    if (site.match(/redgreenreader/)) {
      setPathway("redgreen");
    } else if (user?.role === "User") {
      setPathway("builder");
    } else {
      setPathway("");
    }
    setCurrentTest(0);
  }, [token, user, site]);

  useEffect(() => {
    setCurrentTest(0);
  }, [pathway]);

  const doLogout = async () => {
    await logout();
    setToken(null);
    cookie.remove("token");
    setUser({});
  };

  const goBack = () => {
    if (currentTest > 0) {
      setCurrentTest(currentTest - 1);
    }
  };

  const goForward = () => {
    if (currentTest < 10) {
      setCurrentTest(currentTest + 1);
    }
  };
  const isOnRedGreenReader = site.match(/redgreenreader/);

  return (
    <div>
      {!token && <Login />}
      {token && Object.keys(user).length > 0 && (
        <>
          <Navbar color="light" light expand="md">
            <Nav className="mr-auto" navbar>
              <NavbarBrand>
                {isOnRedGreenReader ? "Red Green Reader" : "BV Trainer"}
              </NavbarBrand>
              <NavbarText className="mr-2">
                {user.forename} {user.surname} <i>{user.role}</i>
              </NavbarText>

              {pathway && !isOnRedGreenReader && user.role !== "User" && (
                <NavItem>
                  <Button
                    outline
                    color="secondary"
                    onClick={() => setPathway("")}
                  >
                    {pathway}
                  </Button>
                </NavItem>
              )}
            </Nav>
            <Nav className="ml-auto">
              {pathway === "builder" && (
                <>
                  <NavItem>
                    <Button
                      outline
                      size="sm"
                      color="primary"
                      className="mg-5"
                      onClick={goBack}
                    >
                      &lt;
                    </Button>
                  </NavItem>
                  <NavItem>
                    <Button
                      outline
                      size="sm"
                      color="primary"
                      className="mg-5"
                      onClick={goForward}
                    >
                      &gt;
                    </Button>
                  </NavItem>
                </>
              )}

              <NavItem>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  className="mg-5"
                  onClick={doLogout}
                >
                  Logout
                </Button>
              </NavItem>
            </Nav>
          </Navbar>
          {pathway === "" && (
            <Container fluid="lg">
              {token && (
                <>
                  <Row className="justify-content-center pad-top-100">
                    <Col xs="auto">
                      <Button
                        color="primary"
                        size="lg"
                        onClick={(e) => setPathway("dev")}
                      >
                        Development path
                      </Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-center pad-top-20">
                    <Col xs="auto">
                      <Button
                        color="primary"
                        size="lg"
                        onClick={(e) => setPathway("builder")}
                      >
                        Builder
                      </Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-center pad-top-20">
                    <Col xs="auto">
                      <Button
                        color="primary"
                        size="lg"
                        onClick={(e) => setPathway("redgreen")}
                      >
                        Red green reader
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          )}
        </>
      )}
      {token && user && Object.keys(user).length > 0 && pathway && (
        <>
          {pathway === "dev" && <DevPath />}
          {pathway === "builder" && (
            <BuilderPath test={currentTest} setCurrentTest={setCurrentTest} />
          )}
          {pathway === "prod" && <ProdPath />}
          {pathway === "redgreen" && <RedGreenPath test={currentTest} />}
        </>
      )}
    </div>
  );
}
