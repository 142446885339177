import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import "./FullSizer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMaximize,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import SizeContext from "./fullSizerContext";

const FullSizer = (props) => {
  const [fullScreen, setFullScreen] = useState(false);
  const backgroundColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.background
  );
  const toggleSize = () => {
    setFullScreen(!fullScreen);
    //   setSize(size.size === "big" ? { size: "Small" } : { size: "big" });
  };

  return (
    <SizeContext.Provider value={fullScreen}>
      <div className={fullScreen ? "fullSizeBackground" : ""}>
        <div
          style={
            fullScreen
              ? {
                  backgroundColor: `rgba(${backgroundColour?.r ?? 255},${
                    backgroundColour?.g ?? 255
                  },${backgroundColour?.b ?? 255},.6)`,
                }
              : {}
          }
          className={fullScreen ? "fullSizeContainer" : ""}
        >
          <div className={fullScreen ? "fullSizeInsert" : ""}>
            <SizeContext.Consumer>
              {(fullScreen) => <>{props.children}</>}
            </SizeContext.Consumer>
          </div>
        </div>
        <Button
          color="primary"
          size="sm"
          className="sizeButton"
          onClick={toggleSize}
        >
          {fullScreen ? (
            <FontAwesomeIcon icon={faWindowRestore} />
          ) : (
            <FontAwesomeIcon icon={faWindowMaximize} />
          )}
        </Button>
      </div>
    </SizeContext.Provider>
  );
};

export default FullSizer;
