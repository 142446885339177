import React, { useEffect, useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./Help.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

// eslint-disable-next-line no-unused-vars
const HelpText = () => (
  <>
    <p>
      This software should only be used under the supervision or at the
      direction of a suitably qualified eyecare professional. Indemnity cover is
      the responsibility of the eyecare professional. Please click below to
      proceed.
    </p>
    <ul>
      <li>I am a qualified Optometrist</li>
      <li>I am a qualified Orthoptist</li>
      <li>
        I am a qualified Ophthalmologist Please indicate that you have
        professional indemnity insurance and are currently registered to
        practice with the professional body in your jurisdiction to proceed.
      </li>
      <li>
        I have professional indemnity insurance and agree that I take full
        responsibility for administering this test.
      </li>
    </ul>
  </>
);

// eslint-disable-next-line no-unused-vars
const RedGreenReaderHelp = () => (
  <>
    <p>RED/GREEN READER</p>{" "}
    <ul>
      <li>Ask subject to put on Red/Green (or Red/Blue) filter glasses.</li>
      <li>Cover the eye which has the green filter.</li>
      <li>Click on the Red Colour box</li>
      <li>Adjust intensity (lower) slider to max</li>
      <li>
        Then adjust colour slider (upper) until Red Colour box appears white
      </li>
      <li>Close box</li>
      <li>
        Repeat above covering eye which has the red filter and adjust Green
        Colour sliders ensuring box appears white.
      </li>
      <li>Close box.</li>
      <li>Use left/right boxes at top of screen to scroll through tests</li>
      <li>
        Text: select appropriate text level, font size and size. In most cases
        use either red/green but if words seem to move around set
        red/green/black.
      </li>
      <li>
        Word timer: select text level, font size. Click to start counter to
        begin text. Click space bar when word is read correctly and click “p”
        key to pass.
      </li>
      <li>Report; Under development</li>
    </ul>
  </>
);

const BuilderHelpText = () => (
  <>
    <p>BUILDER</p>
    <ul>
      <li>Ask subject to put on Red/Green (or Red/Blue) filter glasses.</li>
      <li>Cover the eye which has the green filter.</li>
      <li>Click on the Red Colour box </li>
      <li>Adjust intensity (lower) slider to max</li>
      <li>
        Then adjust colour slider (upper) until Red Colour box appears white
      </li>
      <li>Close box</li>
      <li>
        Repeat above covering eye which has the red filter and adjust Green
        Colour sliders ensuring box appears white.
      </li>
      <li>Close box.</li>
      <li>Use left/right boxes at top of screen to scroll through tests</li>
      <li>
        Image size test: Select “By scale and position”, click on the red/green
        box and move sides using left/right/up/down keys on keyboard to minimise
        size difference. Size difference will be shown above “Test settings”
        box.
      </li>
      <li>
        Image size (Half square) test: With rotation left set at zero deg click
        on red/green box image then use left right buttons on keyboard to
        equalise image size. Then repeat with rotation set to 90.
      </li>
      <li>
        Near Far Rock chart generator: Click on “AZ” button to select
        upper/lower/mixed case. Select font size on configuration page (suggest
        setting this to 3). Select “Normal” to print distance chart. Use
        Plus/Minus to ensure fills page for your printer set up. Select “Tiny”
        to print near chart.
      </li>
      <li>
        Text: select appropriate text level, font size and size. In most cases
        use either red/green but if words seem to move around set
        red/green/black.
      </li>
      <li>
        Word timer: select text level, font size. Click to start counter to
        begin text. Click space bar when word is read correctly and click “p”
        key to pass.
      </li>
      <li>Report; Under development</li>
    </ul>
  </>
);

const helpTexts = {
  "word timer": {
    title: "Word timer help",
    // eslint-disable-next-line react/display-name
    text: () => (
      <p>
        Select text level, font size.
        <br />
        Click to start counter to begin text.
        <br />
        Click space bar when word is read correctly and click “p” key to pass,
        or use the buttons below the test.
      </p>
    ),
  },
};
function HelpDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => setOpen(false), []);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faQuestion} />
      </Button>
      <Modal isOpen={open} fade={false} toggle={handleClose} size="lg">
        <ModalHeader toggle={handleClose}>
          {helpTexts[props?.whichTest]?.title ?? "Help"}
        </ModalHeader>
        <ModalBody>
          {helpTexts[props?.whichTest]?.text ? (
            helpTexts[props.whichTest].text()
          ) : (
            <BuilderHelpText />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
/*
function Help(props) {
  const { adjustmentMode, whichTest } = props;
  const [helpOpen, setHelpOpen] = useState(false);

  const onClickHelpOpen = () => {
    setHelpOpen(!helpOpen);
  };
  return (
    <div className="help">
      <div>
        <button onClick={onClickHelpOpen}>
          <FontAwesomeIcon icon={faQuestion} />{" "}
        </button>
      </div>
      {helpOpen && (
        <div className="helpBox">
          {whichTest === "word timer" && (
            <>
              <p>
                Press space if the word has been read, press p to pass on a
                word.
              </p>
            </>
          )}
          {whichTest === undefined && (
            <>
              {adjustmentMode === "by_edge" && (
                <>
                  <p>
                    Click on the square to give it focus. Use UP DOWN LEFT RIGHT
                    arrows to select an edge to position
                  </p>
                  <p>Hit 'Escape' to remove highlight from the edge.</p>
                  <p>
                    Use the Enter and Backspace keys to move the selected edge
                    back and forth.
                  </p>
                </>
              )}
              {adjustmentMode === "by_scale" && (
                <>
                  <p>
                    Click on the square to give it focus. Use UP DOWN LEFT RIGHT
                    arrows to size the square horizontally and vertically
                  </p>
                  <p>Use W, A, S, and D to move the square to position</p>
                </>
              )}
              <p>Use 1 and 2 to resize the square as a whole</p>
            </>
          )}
        </div>
      )}
    </div>
  );
}
*/

export default HelpDialog;
