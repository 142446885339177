import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Row, Label } from "reactstrap";

import {
  setBackgroundColour,
  setRedColour,
  setGreenColour,
} from "../actions/actions";
import ColourSlider from "../Components//ColourSlider/ColourSlider";
import { fullColourHex, fullColourRGB } from "../utils/Utils";

import ColourPicker from "../Components/ColourPicker/ColourPicker";

import Color from "color";

export default function RedGreeenSettings({ doContinue }) {
  const [pickerOn, setPickerOn] = useState("");

  const closeColourPicker = () => {
    setPickerOn("");
  };

  const [whichColour, setWhichColour] = useState("red");
  ///
  const [hue, setHue] = useState(0);

  const dispatch = useDispatch();
  const redColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.red
  );
  const greenColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.green
  );

  const doSetRedColour = (value) => dispatch(setRedColour(value));
  const doSetGreenColour = (value) => dispatch(setGreenColour(value));

  const backgroundColour = useSelector((state) => {
    const bg = state.profiles[state.activeProfile].colours.background;
    console.log(`Get background ${JSON.stringify(bg)}`);
    return bg;
  });
  const doSetBackgroundColour = (value) => dispatch(setBackgroundColour(value));

  const circleStyle = (colour) => ({
    marginTop: 16,
    background: fullColourHex(colour === "red" ? redColour : greenColour),
    borderRadius: "50%",
    width: (120 * 60) / 100,
    height: (120 * 60) / 100,
    marginLeft: "auto",
    marginRight: "auto",
  });

  const ranges = {
    red: { offset: 0, size: 180, normalise: (v) => (v + 90) % 360 },
    green: { offset: 120, size: 180, normalise: (v) => (v - 120) % 360 },
  };

  useEffect(() => {
    const cRef = whichColour === "red" ? redColour : greenColour;
    const c = Color.rgb(cRef.r, cRef.g, cRef.b);
    setHue(Math.floor(c.hue() + 0.5));

    //   setBrightness(Math.floor(c.lightness() + 0.5));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whichColour]);

  const Instructions = ({ colour }) => {
    const invColour = colour === "red" ? "green" : "red";
    return (
      <ul style={{ listStyle: "none" }}>
        <li>
          Put the glasses on with the red filter in front of eye with better
          vision.
        </li>
        <li>
          Cover the eye with the{" "}
          <span style={{ color: invColour, textTransform: "uppercase" }}>
            {invColour}
          </span>{" "}
          coloured filter
        </li>
        <li>
          Adjust the slider on the coloured bar until you cannot see the
          coloured text or the circle
        </li>
        <li>When you are finished click the NEXT button</li>
        {colour === "green" && (
          <li>
            If you want to go back and adjust{" "}
            <span style={{ color: invColour, textTransform: "uppercase" }}>
              RED
            </span>{" "}
            again use the BACK button
          </li>
        )}
      </ul>
    );
  };

  const prevSetting = () => {
    setWhichColour("red");
  };

  const nextSetting = () => {
    if (whichColour === "red") {
      setWhichColour("green");
    } else {
      doContinue();
    }
  };

  const setColourFromHueAndBrightness = (hue, luminance) => {
    const c = Color.hsl(hue, 100, luminance / 2);
    const setColour = {
      r: Math.floor(c.red() + 0.5),
      g: Math.floor(c.green() + 0.5),
      b: Math.floor(c.blue() + 0.5),
      a: 1,
    };
    if (whichColour === "red") {
      doSetRedColour(setColour);
    } else {
      doSetGreenColour(setColour);
    }
  };

  const newSetHue = (newHue) => {
    setHue(newHue);
    const luminance = 100;
    setColourFromHueAndBrightness(newHue, luminance);
  };

  /*
  const cRef = whichColour === "red" ? redColour : greenColour;
  const c = Color.rgb(cRef.r, cRef.g, cRef.b);
*/

  return (
    <>
      <Row className="justify-content-center mt-4">
        <Col xs={{ size: 4, offset: 4 }}>
          <h2 className="text-center">
            <span style={{ color: "red" }}>Red</span>
            <span style={{ color: "green" }}>Green</span>Settings
          </h2>
        </Col>
        <Col xs={{ size: 4 }} className="text-center">
          {whichColour === "green" && (
            <Button className="mr-2" color="primary" onClick={prevSetting}>
              BACK
            </Button>
          )}
          <Button color="primary" onClick={nextSetting}>
            NEXT
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={{ size: 12 }} className="text-center">
          <Instructions colour={whichColour}></Instructions>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <Row>
            <Col xs={{ size: 5 }} className="text-right">
              <p style={{ fontSize: "4em" }}>
                [
                <span
                  style={{
                    textTransform: "uppercase",
                    color: fullColourHex(
                      whichColour === "red" ? redColour : greenColour
                    ),
                  }}
                >
                  {whichColour}
                </span>
                ]
              </p>
            </Col>
            <Col xs={{ size: 2 }}>
              <div style={circleStyle(whichColour)}></div>
            </Col>

            <Col xs={3}>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      right: 180,
                      bottom: 10,
                    }}
                  >
                    <ColourPicker
                      pickerOn={pickerOn === "background"}
                      initialColour={backgroundColour}
                      setPickedColour={(colour, e) => {
                        doSetBackgroundColour(colour.rgb);
                      }}
                      closeColourPicker={closeColourPicker}
                    />
                  </div>
                  <Label for="backgroundColourPicker">Background colour</Label>
                  <Button
                    id="backgroundColourPicker"
                    name="backgroundColourPicker"
                    onClick={() => setPickerOn("background")}
                    className="pickerButton ml-2"
                    title={fullColourRGB(backgroundColour)}
                  >
                    <div
                      style={{
                        backgroundColor: fullColourHex(backgroundColour, false),
                      }}
                    >
                      &nbsp;
                    </div>
                  </Button>
                </Col>
                <Col xs={6} className="text-center">
                  <Button
                    size="sm"
                    color="primary"
                    className="ml-2"
                    onClick={() =>
                      doSetBackgroundColour({ r: 255, g: 255, b: 255 })
                    }
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 10, offset: 1 }} className="mt-4">
              <ColourSlider
                size={ranges[whichColour].size}
                offset={ranges[whichColour].offset}
                normalise={ranges[whichColour].normalise}
                hueIn={hue}
                luminance={100}
                value={hue}
                setValue={newSetHue}
              />
            </Col>
          </Row>

          {/*   <Row className="mt-4">
            <Col xs={{ size: 12 }} className="text-center">
              <FormGroup>
                <Label xs={5} for="luminanceInput">
                  Luminance
                </Label>
                              <Col xs={4}>
                  <RangeSlider
                    id="luminanceInput"
                    name="luminanceInput"
                    min={30}
                    max={100}
                    step={1}
                    value={
                      whichColour === "red" ? redLuminance : greenLuminance
                    }
                    onChange={(e) =>
                      whichColour === "red"
                        ? doSetRedLuminance(e.target.value)
                        : doSetGreenLuminance(e.target.value)
                    }
                  />
                  </Col> 
                <Col xs={3}>
                  <FormText>
                    Luminance [{whichColour} {redLuminance} {greenLuminance}
                    {whichColour === "red" ? redLuminance : greenLuminance}]
                  </FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>*/}
        </Col>
      </Row>
    </>
  );
}
