import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import TextTestDisplaySettings from "./TextTestDisplaySettings";
import TextTestReadingSettings from "./TextTestReadingSettings";

import "./TextSettings.css";

function TextSettings() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            Text settings
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => {
              toggle("2");
            }}
          >
            Display settings
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <TextTestReadingSettings />
        </TabPane>
        <TabPane tabId="2">
          <TextTestDisplaySettings />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default TextSettings;
