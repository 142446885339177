import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Nav, Navbar } from "reactstrap";
import RedGreenSplash from "../Pages/RedGreeenSplash";
import TextContainer from "../Tests/TextContainer";
import WordTimer from "../Tests/WordTimer";
import RedGreenSettings from "../Settings/RedGreenSettings";

const options = {
  Splash: "Splash",
  RedGreeenSettings: "Settings",
  Text: "Text",
  "Word timer": "Word timer",
};

export default function RedGreenPath() {
  const backgroundColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.background
  );
  const [viewMode, setViewMode] = useState("Splash");

  return (
    <Container
      fluid
      style={{
        height: "100%",
        minHeight: "600px",
        backgroundColor: `rgba(${backgroundColour?.r ?? 255},${
          backgroundColour?.g ?? 255
        },${backgroundColour?.b ?? 255},.6)`,
      }}
    >
      <main>
        <Navbar color="faded" light>
          <Nav navbar>
            <ol className="menuList">
              {Object.keys(options).map((option) => (
                <li
                  tabIndex="1"
                  className="menuItem"
                  key={option}
                  onClick={() => setViewMode(option)}
                >
                  {options[option]}
                </li>
              ))}
            </ol>
          </Nav>
        </Navbar>
        <Row noGutters>
          <Col xs={12}>
            <div className="mainBlock">
              {viewMode === "Splash" && (
                <RedGreenSplash
                  doContinue={() => setViewMode("RedGreeenSettings")}
                />
              )}
              {viewMode === "RedGreeenSettings" && (
                <RedGreenSettings doContinue={() => setViewMode("Text")} />
              )}
              {viewMode === "Text" && <TextContainer />}
              {viewMode === "Word timer" && <WordTimer />}
            </div>
          </Col>
        </Row>
      </main>
    </Container>
  );
}
