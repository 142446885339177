import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import useInterval from "../hooks/useInterval";
import Configuration from "../Settings/Configuration";
import TestSettings from "../Settings/TestSettings";
import Profiles from "../Settings/Profiles";
import Help from "../Help";
import Shape from "../Shapes/Shape";
import HalfRect from "../Shapes/HalfRect";
import "./TestContainer.css";
import { fullColourHex } from "../utils/Utils";

function TestContainer(props) {
  const {
    shapeDimensions,
    testAreaDimensions,
    speed,
    redColour,
    greenColour,
    aspectRatio,
    rotation,
    referenceColour,
    adjustmentMode,
  } = props;

  const [shapeTranslation, setShapeTranslation] = useState([0, 0, 0, 0]);
  // const [baseHeightAdjust, setBaseHeightAdjust] = useState(1);
  // const [baseWidthAdjust, setBaseWidthAdjust] = useState(1);
  const [selectedLine, setSelectedLine] = useState(-1);
  const [currentRect, setCurrentRect] = useState(0);
  const [rectColour, setRectColour] = useState(redColour);
  const [testActive, setTestActive] = useState(true);
  useInterval(
    () => {
      let newCurrentRect = (currentRect + 1) & 1;
      setCurrentRect(newCurrentRect);
      if (referenceColour !== "red") {
        newCurrentRect = (newCurrentRect + 1) & 1;
      }
      setRectColour(newCurrentRect === 0 ? redColour : greenColour);
    },
    testActive ? speed : null
  );
  const updateShapeTranslation = (line, delta) => {
    const newTranslation = [...shapeTranslation];
    if (typeof line === "number" && typeof delta === "number") {
      newTranslation[line] += delta;
    } else {
      line.forEach((lineIndex, idx) => {
        newTranslation[lineIndex] += delta[idx];
      });
    }
    setShapeTranslation(newTranslation);
  };

  const adjustByEdge = (event) => {
    switch (event.key) {
      case "1": {
        event.preventDefault();
        updateShapeTranslation([0, 1, 2, 3], [-1, -1, -1, -1]);
        break;
      }
      case "2": {
        event.preventDefault();
        updateShapeTranslation([0, 1, 2, 3], [1, 1, 1, 1]);
        break;
      }
      case "Escape":
        event.preventDefault();
        setSelectedLine(-1);
        break;
      case "ArrowLeft":
        event.preventDefault();
        setSelectedLine(3);
        break;
      case "ArrowUp":
        event.preventDefault();
        setSelectedLine(0);
        break;
      case "ArrowRight":
        event.preventDefault();
        setSelectedLine(1);
        break;
      case "ArrowDown":
        event.preventDefault();
        setSelectedLine(2);
        break;
      case "Enter":
        event.preventDefault();
        updateShapeTranslation(selectedLine, 1);
        break;
      case "Backspace":
        event.preventDefault();
        updateShapeTranslation(selectedLine, -1);
        break;
      default:
        break;
    }
  };
  const adjustByScale = (event) => {
    switch (event.key) {
      case "1": {
        event.preventDefault();
        updateShapeTranslation([0, 1, 2, 3], [-1, -1, -1, -1]);
        break;
      }
      case "2": {
        event.preventDefault();
        updateShapeTranslation([0, 1, 2, 3], [1, 1, 1, 1]);
        break;
      }
      case "ArrowLeft":
        event.preventDefault();
        updateShapeTranslation([1, 3], [-1, -1]);
        break;
      case "ArrowUp":
        event.preventDefault();
        updateShapeTranslation([0, 2], [1, 1]);
        break;
      case "ArrowRight":
        event.preventDefault();
        updateShapeTranslation([1, 3], [1, 1]);
        break;
      case "ArrowDown":
        event.preventDefault();
        updateShapeTranslation([0, 2], [-1, -1]);
        break;
      case "a":
      case "A": {
        event.preventDefault();
        updateShapeTranslation([1, 3], [-1, 1]);
        break;
      }
      case "d":
      case "D": {
        event.preventDefault();
        updateShapeTranslation([1, 3], [1, -1]);
        break;
      }
      case "s":
      case "S": {
        event.preventDefault();
        updateShapeTranslation([0, 2], [-1, 1]);
        break;
      }
      case "w":
      case "W": {
        event.preventDefault();
        updateShapeTranslation([0, 2], [1, -1]);
        break;
      }
      default:
        break;
    }
  };

  const adjustBaseOffsets = (event) => {
    //   38,39,40,37 = up right down left

    if (adjustmentMode === "by_edge") {
      adjustByEdge(event);
    } else {
      adjustByScale(event);
    }
  };

  const generateShapes = () => {
    let x = -(shapeDimensions[0] / 2);
    let x1 = shapeDimensions[0] / 2;
    let x2 = x1;
    let x3 = x;

    let y = -(shapeDimensions[1] / 2);
    let y1 = y;
    let y2 = shapeDimensions[1] / 2;
    let y3 = y2;

    const shape = { x: [x, x1, x2, x3], y: [y, y1, y2, y3] };

    y -= shapeTranslation[0];
    y1 -= shapeTranslation[0];
    x1 += shapeTranslation[1];
    x2 += shapeTranslation[1];
    y2 += shapeTranslation[2];
    y3 += shapeTranslation[2];
    x3 -= shapeTranslation[3];
    x -= shapeTranslation[3];

    const distortedShape = { x: [x, x1, x2, x3], y: [y, y1, y2, y3] };
    return {
      shape,
      distortedShape,
    };
  };

  const mouseClick = (e) => {
    console.log("Mouse click");
  };

  const { shape, distortedShape } = generateShapes();

  const width = shapeTranslation[1] + shapeTranslation[3];
  const height = shapeTranslation[2] + shapeTranslation[0];
  const widthChangePercent = (100 * (width / shapeDimensions[0])).toFixed(2);
  const heightChangePercent = (100 * (height / shapeDimensions[1])).toFixed(2);
  const shapeDistortion = shapeTranslation.map((shapeOffs, idx) => {
    return (100 * (shapeOffs / parseFloat(shapeDimensions[idx % 1]))).toFixed(
      2
    );
  });
  return (
    <>
      <h3 className="my-4 text-center">Image size laboratory version</h3>
      <Row>
        <Col xs={6}>
          <div tabIndex="0" onKeyDown={adjustBaseOffsets} onClick={mouseClick}>
            <Shape
              testAreaDimensions={testAreaDimensions}
              shape={currentRect === 0 ? shape : distortedShape}
              rectColour={rectColour}
              currentRect={currentRect}
              selectedLine={selectedLine}
              rotation={rotation}
              aspectRatio={aspectRatio}
            />
            <HalfRect
              testAreaDimensions={testAreaDimensions}
              shape={shape}
              distortedShape={distortedShape}
              redColour={redColour}
              greenColour={greenColour}
              rotation={rotation}
              aspectRatio={aspectRatio}
            />
          </div>
        </Col>
        <Col xs={6}>
          Left change: {shapeDistortion[3]}%, Top change: {shapeDistortion[0]}%,
          Width change: {widthChangePercent}%, Height change:{" "}
          {heightChangePercent}%
          <TestSettings />
          <Configuration />
          <button onClick={() => setTestActive(!testActive)}>
            {testActive ? "Deactivate" : "Activate"}
          </button>
          <Profiles />
          <Help />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  shapeDimensions: state.profiles[state.activeProfile].dimensions.shape,
  testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea,
  redColour: fullColourHex(state.profiles[state.activeProfile].colours.red),
  greenColour: fullColourHex(state.profiles[state.activeProfile].colours.green),
  speed: state.profiles[state.activeProfile].speed,
  aspectRatio: state.profiles[state.activeProfile].aspectRatio,
  rotation: state.profiles[state.activeProfile].rotation,
  referenceColour: state.profiles[state.activeProfile].referenceColour,
  adjustmentMode: state.profiles[state.activeProfile].adjustmentMode,
});

export default connect(mapStateToProps)(TestContainer);
