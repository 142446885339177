import React, { useEffect } from "react";
import Gateway from "./Gateway";
import { setSite } from "./actions/actions";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSite(window.location.hostname));
  });

  return <Gateway />;
}

export default App;
