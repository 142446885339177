import React, { useState } from "react";
import Shape from "../Shapes/Shape";
import { fullColourHex } from "../utils/Utils";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./DisparitySquare.css";

function DisparitySquare(props) {
  const {
    testAreaDimensions,
    shapeDimensions,
    aspectRatio,
    redColour,
    greenColour,
  } = props;

  const [shapeTranslation, setShapeTranslation] = useState([0, 20, 0, -20]);

  const updateShapeTranslation = (line, delta) => {
    const newTranslation = [...shapeTranslation];
    if (typeof line === "number") {
      newTranslation[line] += delta;
    } else {
      line.forEach((lineIndex, idx) => {
        newTranslation[lineIndex] += delta[idx];
      });
    }
    setShapeTranslation(newTranslation);
  };

  const adjustBaseOffsets = (event) => {
    switch (event.key) {
      case "ArrowLeft":
        event.preventDefault();
        updateShapeTranslation([1, 3], [-1, 1]);
        break;

      case "ArrowRight":
        event.preventDefault();
        updateShapeTranslation([1, 3], [1, -1]);
        break;

      default:
        break;
    }
  };

  const createShapes = () => {
    let x = -(shapeDimensions[0] / 2);
    let x1 = shapeDimensions[0] / 2;
    let x2 = x1;
    let x3 = x;

    let y = -(shapeDimensions[1] / 2);
    let y1 = y;
    let y2 = shapeDimensions[1] / 2;
    let y3 = y2;

    const shape = { x: [x, x1, x2, x3], y: [y, y1, y2, y3] };

    y -= shapeTranslation[0];
    y1 -= shapeTranslation[0];
    x1 += shapeTranslation[1];
    x2 += shapeTranslation[1];
    y2 += shapeTranslation[2];
    y3 += shapeTranslation[2];
    x3 -= shapeTranslation[3];
    x -= shapeTranslation[3];
    const translatedShape = { x: [x, x1, x2, x3], y: [y, y1, y2, y3] };
    return [shape, translatedShape];
  };
  const width = shapeTranslation[1] + shapeTranslation[3];
  const height = shapeTranslation[2] + shapeTranslation[0];
  const widthChangePercent = (100 * (width / shapeDimensions[0])).toFixed(2);
  const heightChangePercent = (100 * (height / shapeDimensions[1])).toFixed(2);
  const shapeDistortion = shapeTranslation.map((shapeOffs, idx) => {
    return (100 * (shapeOffs / parseFloat(shapeDimensions[idx % 1]))).toFixed(
      2
    );
  });
  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <div>Disparity square</div>
        <span>
          Left change: {shapeDistortion[3]}%, Top change: {shapeDistortion[0]}%,
          Width change: {widthChangePercent}%, Height change:{" "}
          {heightChangePercent}%
        </span>
        <div tabIndex="0" onKeyDown={adjustBaseOffsets}>
          <Shape
            testAreaDimensions={testAreaDimensions}
            shape={createShapes()}
            rectColour={[redColour, greenColour]}
            currentRect={0}
            selectedLine={-1}
            rotation={0}
            aspectRatio={aspectRatio}
          />
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  redColour: fullColourHex(state.profiles[state.activeProfile].colours.red),
  greenColour: fullColourHex(state.profiles[state.activeProfile].colours.green),
  shapeDimensions: state.profiles[state.activeProfile].dimensions.shape,
  testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea,
  aspectRatio: state.profiles[state.activeProfile].aspectRatio,
});

export default connect(mapStateToProps)(DisparitySquare);
