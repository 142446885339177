export const decToHex = (num) => {
  let hex = Number(num).toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
};

export const fullColourRGB = (col) => {
  const colour = col ?? { r: 0, g: 0, b: 0, a: 0 };
  return `Red: ${colour.r} Green: ${colour.g} Blue: ${colour.b}`;
};

export const fullColourHex = (col, useAlpha = true) => {
  const colour = col ?? { r: 0, g: 0, b: 0, a: 0 };
  return useAlpha
    ? `#${decToHex(colour.r)}${decToHex(colour.g)}${decToHex(
        colour.b
      )}${decToHex(Math.floor(colour.a * 255))}`
    : `#${decToHex(colour.r)}${decToHex(colour.g)}${decToHex(colour.b)}`;
};

export const hexToFullColour = (hex) => ({
  r: parseInt(hex.substr(1, 2), 16),
  g: parseInt(hex.substr(3, 2), 16),
  b: parseInt(hex.substr(5, 2), 16),
  a: hex.length > 7 ? parseInt(hex.substr(7, 2), 16) : 1,
});

export const rotateShape = (shape, rotation) => {
  const angle = parseFloat(rotation);
  const newShape = { x: [], y: [] };
  const sin = Math.sin(angle * (Math.PI / 180));
  const cos = Math.cos(angle * (Math.PI / 180));
  for (let i = 0; i < shape.x.length; i++) {
    newShape.x[i] = shape.x[i] * cos - shape.y[i] * sin;
    newShape.y[i] = shape.y[i] * cos + shape.x[i] * sin;
  }
  return newShape;
};

export const positionShape = (shape, aspectRatio, testAreaDimensions) => {
  const newShape = { x: [], y: [] };
  for (let i = 0; i < shape.x.length; i++) {
    newShape.x[i] = shape.x[i] * aspectRatio;
    newShape.x[i] += testAreaDimensions[0] / 2;
    newShape.x[i] = Math.floor(newShape.x[i] + 0.5);
    newShape.y[i] = shape.y[i] + testAreaDimensions[1] / 2;
    newShape.y[i] = Math.floor(newShape.y[i] + 0.5);
  }
  return newShape;
};
