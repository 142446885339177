import React from "react";
import { Col, Row } from "reactstrap";
import "./Settings.css";

import Profiles from "./Profiles";
import Configuration from "./Configuration";
import TestSettings from "./TestSettings";
import TextTestReadingSettings from "./TextTestReadingSettings";
import TextTestDisplaySettings from "./TextTestDisplaySettings";
import NearAcuitySettings from "./NearAcuitySettings";
import WordTimerSettings from "./WordTimerSettings";
import NearFarSettings from "./NearFarSettings";

export default function Settings(props) {
  const profiles = props?.profiles;
  const configuration = props?.configuration;
  const testSettings = props?.testsettings;
  const nearAcuity = props?.nearacuity;
  const wordTimer = props?.wordtimer;
  const nearFar = props?.nearfar;
  const text = props?.text;

  return (
    <div className="settings">
      <Row>
        <Col xs={{ size: 12 }}>
          <h3 className="text-center my-4">Settings</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {profiles && <Profiles />}
          {configuration && <Configuration />}
          {testSettings && <TestSettings />}
          {nearAcuity && <NearAcuitySettings />}
          {wordTimer && <WordTimerSettings />}
          {nearFar && <NearFarSettings />}
        </Col>
        <Col xs={6}>
          {text && <TextTestReadingSettings />}
          {text && <TextTestDisplaySettings />}
        </Col>
      </Row>
    </div>
  );
}
