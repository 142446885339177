import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { rotateShape, positionShape } from "../utils/Utils";
import "./Oblong.css";

function Oblong(props) {
  const canvasRef = useRef(null);

  const {
    testAreaDimensions,
    shape,
    distortedShape,
    redColour,
    greenColour,
    rotation,
    aspectRatio,
    lineThickness,
    crossLength,
    crossThickness,
  } = props;

  const drawHalfRect = (ctx, shapeDimensions, rectColour, shapeRotation) => {
    ctx.lineWidth = 1;
    ctx.strokeStyle = rectColour;
    ctx.fillStyle = rectColour;
    ctx.shadowBlur = 0;
    const sin = Math.sin(shapeRotation * (Math.PI / 180));
    const cos = Math.cos(shapeRotation * (Math.PI / 180));
    const sinLw = sin * (lineThickness / 2);
    const cosLw = cos * (lineThickness / 2);
    const xadd = -sinLw + cosLw;
    const yadd = sinLw + cosLw;
    const midxR =
      shapeDimensions.x[1] + (shapeDimensions.x[2] - shapeDimensions.x[1]) / 2;
    const midyR =
      shapeDimensions.y[1] + (shapeDimensions.y[2] - shapeDimensions.y[1]) / 2;
    const midxL =
      shapeDimensions.x[0] + (shapeDimensions.x[3] - shapeDimensions.x[0]) / 2;
    const midyL =
      shapeDimensions.y[0] + (shapeDimensions.y[3] - shapeDimensions.y[0]) / 2;
    const points = [
      [shapeDimensions.x[0] - xadd, shapeDimensions.y[0] - yadd],
      [shapeDimensions.x[1] + yadd, shapeDimensions.y[1] - xadd],
      [midxR + cosLw, midyR + sinLw],
      [midxL - cosLw, midyL - sinLw],
    ];
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    points.forEach((point, idx) => {
      if (idx > 0) {
        ctx.lineTo(point[0], point[1]);
      }
    });
    ctx.lineTo(points[0][0], points[0][1]);
    ctx.fill();
    ctx.stroke();
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");
    // clear the canvas area before rendering the coordinates held in state
    ctx.clearRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);

    let newRot = (180 + parseFloat(rotation)) % 360;
    let newShape = rotateShape(shape, newRot);
    newShape = positionShape(newShape, aspectRatio, testAreaDimensions);
    drawHalfRect(ctx, newShape, greenColour, newRot);

    let newDistortedShape = rotateShape(distortedShape, parseFloat(rotation));
    newDistortedShape = positionShape(
      newDistortedShape,
      aspectRatio,
      testAreaDimensions
    );
    drawHalfRect(ctx, newDistortedShape, redColour, parseFloat(rotation));

    ctx.beginPath();
    ctx.lineWidth = crossThickness;
    ctx.shadowBlur = 0;
    ctx.strokeStyle = "#000000";
    ctx.moveTo(
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] / 2 - parseInt(crossLength, 10)
    );
    ctx.lineTo(
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] / 2 + parseInt(crossLength, 10)
    );
    ctx.moveTo(
      testAreaDimensions[0] / 2 - parseInt(crossLength, 10),
      testAreaDimensions[1] / 2
    );
    ctx.lineTo(
      testAreaDimensions[0] / 2 + parseInt(crossLength, 10),
      testAreaDimensions[1] / 2
    );

    ctx.stroke();
  });
  return (
    <div className="App-canvas-container" tabIndex="0">
      <canvas
        className="App-canvas"
        ref={canvasRef}
        width={testAreaDimensions[0]}
        height={testAreaDimensions[1]}
      ></canvas>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lineThickness: parseInt(
    state.profiles[state.activeProfile].lineThickness,
    10
  ),
  crossThickness: parseInt(
    state.profiles[state.activeProfile].crossThickness,
    10
  ),
  crossLength: parseInt(state.profiles[state.activeProfile].crossLength, 10),
});

export default connect(mapStateToProps)(Oblong);
