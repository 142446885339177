import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { login, hello } from "./utils/Api";
import { setUser } from "./actions/actions";
import MD5 from "crypto-js/md5";
import Cookies from "universal-cookie";
import "./Login.css";

function Login(props) {
  const { setUser } = props;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {}, [token]);
  const cookie = new Cookies();
  const doLogin = async () => {
    const token = await login(userName, MD5(password).toString());
    if (token) {
      setToken(token);
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      cookie.set("token", token, { expires });
      const user = await hello();
      setUser(user);
      console.log(user);
    }
  };
  return (
    <Container fluid="lg" className="loginWrap">
      <h2 className="m-4 text-center">BV Trainer</h2>
      <Form>
        {token === null && (
          <>
            <FormGroup row>
              <Label for="username" sm={4} className="text-right">
                User name
              </Label>
              <Col sm={4}>
                <Input
                  name="username"
                  id="username"
                  placeholder="User name"
                  value={userName}
                  autoComplete="username"
                  onChange={(e) => setUserName(e.target.value)}
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="password" sm={4} className="text-right">
                Password
              </Label>
              <Col sm={4}>
                <Input
                  name="password"
                  type="password"
                  value={password}
                  autoComplete="user-password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      doLogin();
                    }
                  }}
                ></Input>
              </Col>
            </FormGroup>
          </>
        )}
        <Row className="justify-content-center">
          <Col xs="auto">
            {token === null && (
              <Button
                color="primary"
                size="lg"
                onClick={() => doLogin()}
                disabled={userName.length === 0 || password.length === 0}
              >
                Login
              </Button>
            )}
            {token !== null && (
              <Button
                color="primary"
                size="lg"
                onClick={() => doLogin()}
                disabled={userName.length === 0 || password.length === 0}
              >
                Logout
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch, value) => {
  return {
    setUser: (value) => dispatch(setUser(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
