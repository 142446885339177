import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import {
    setActiveProfile,
    addProfile
} from "../actions/actions";

import "./Profiles.css";

function Profiles(props) {
    const {
        activeProfile,
        setActiveProfile,
        profiles,
        addProfile
    } = props;
    const [profileScratchPad, setProfileScratchPad] = useState('');

    return (
        <div className="profiles">
            <h4>Profiles</h4>
            <Form>
                <FormGroup row>
                    <Label for="profileName" sm={4}>Profile name</Label>
                    <Col sm={4}>
                        <Input name="profileName" id="profileName" value={profileScratchPad} placeholder="profile name" onChange={e => setProfileScratchPad(e.target.value)} />
                    </Col>
                    <Col sm={4}>
                        <Button color="primary" disabled={profileScratchPad.length === 0} onClick={() => addProfile(profileScratchPad)}>Add profile</Button>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={4} for="activeTextSelect">Select active profile</Label>
                    <Col sm={4}>
                        <Input type="select" disabled={profiles.length === 0} id="activeProfileSelect" name="activeTextSelect" defaultValue={activeProfile} onChange={e => setActiveProfile(e.target.value)}>
                            <option disabled={profiles.length === 1} key='_' value='_'>[default profile]</option>
                            {profiles.filter(profile => profile !== '_').map(profile =>
                                <option key={profile} value={profile} onChange={e => setActiveProfile(e.target.value)}>{profile}</option>
                            )}
                        </Input>
                    </Col>
                </FormGroup>
            </Form>
        </div >
    );
}
const mapStateToProps = state => ({
    activeProfile: state.activeProfile,
    profiles: Object.keys(state.profiles)
});
const mapDispatchToProps = (dispatch, value) => ({
    setActiveProfile: (value) => dispatch(setActiveProfile(value)),
    addProfile: (value) => dispatch(addProfile(value))
});



export default connect(mapStateToProps, mapDispatchToProps)(Profiles);