import axios from "axios";
import Cookies from "universal-cookie";

export async function getSettings() {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}user/settings`;
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.get(url);
    const settings =
      response.data.settings !== undefined ? response.data.settings : "";
    if (settings) {
      return JSON.parse(settings);
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function setSettings(settings) {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}user/settings`;
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.post(url, {
      settings: JSON.stringify(settings),
    });
    const tokenId =
      response.data.tokenId !== undefined ? response.data.tokenId : "";
    if (tokenId) {
      return tokenId;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function hello() {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}hello`;
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.get(url);
    console.log(JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.log(error);
    cookie.remove("token");
    return false;
  }
}

export async function login(userName, password) {
  console.log(JSON.stringify(process.env));
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}login`;
  console.log(url);
  try {
    const response = await axios.post(url, {
      userName,
      password,
    });
    const tokenId =
      response.data.tokenId !== undefined ? response.data.tokenId : "";
    if (tokenId) {
      return tokenId;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function logout() {
  const cookie = new Cookies();
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}logout`;
  const token = cookie.get("token");
  console.log(url);
  if (!token) {
    return;
  }
  axios.defaults.headers.common["Authorisation"] = token;
  try {
    await axios.post(url);
    cookie.remove("token");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const apiGetBook = async (book, section) => {
  const cookie = new Cookies();
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}book/${book}/section/${section}`;
  const token = cookie.get("token");
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.get(url);
    console.log(JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.log(error);
    cookie.remove("token");
    return false;
  }
};

export const apiGetBooks = async () => {
  const cookie = new Cookies();
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}book/`;
  const token = cookie.get("token");
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    cookie.remove("token");
    return false;
  }
};

export const apiSearchBooks = async (search) => {
  const cookie = new Cookies();
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}book/find`;
  const token = cookie.get("token");
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.post(url, { query: search });
    return response.data;
  } catch (error) {
    console.log(error);
    cookie.remove("token");
    return false;
  }
};

export const apiSelectBook = async (book) => {
  const cookie = new Cookies();
  const url = `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_PREFIX}book/select`;
  const token = cookie.get("token");
  console.log(url);
  if (token) {
    axios.defaults.headers.common["Authorisation"] = token;
  } else {
    console.log("No token");
    cookie.remove("token");
    axios.defaults.headers.common["Authorisation"] = null;
    return false;
  }
  try {
    const response = await axios.post(url, {
      bookId: book.number,
      bookTitle: book.title,
    });
    return response.data.result;
  } catch (error) {
    console.log(error);
    cookie.remove("token");
    return false;
  }
};
