import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { rotateShape, positionShape } from "../utils/Utils";
import "./Shape.css";

function Shape(props) {
  const canvasRef = useRef(null);

  const {
    testAreaDimensions,
    shape, // can be an array
    aspectRatio,
    rectColour, /// can be an array
    currentRect,
    rotation,
    selectedLine,
    lineThickness,
    crossThickness,
    crossLength,
  } = props;

  const drawRect = (ctx, shapeDimensions, rectColour) => {
    /*  if (selectedLine === 1) {
      ctx.beginPath();
      ctx.strokeStyle = "#0000ff";
      ctx.lineWidth = lineWidth + 4;
      ctx.moveTo(shapeDimensions.x[1], shapeDimensions.y[1]);
      ctx.lineTo(shapeDimensions.x[2], shapeDimensions.y[2]);
      //      ctx.moveTo(shapeDimensions.x[0], shapeDimensions.y[0]);
      ctx.stroke();
    }
*/
    ctx.lineWidth = lineThickness;

    ctx.strokeStyle = rectColour;
    ctx.shadowBlur = 0;
    ctx.beginPath();
    ctx.moveTo(shapeDimensions.x[0], shapeDimensions.y[0]);
    ctx.lineTo(shapeDimensions.x[1], shapeDimensions.y[1]);
    ctx.lineTo(shapeDimensions.x[2], shapeDimensions.y[2]);
    ctx.lineTo(shapeDimensions.x[3], shapeDimensions.y[3]);
    ctx.closePath();
    ctx.stroke();

    if (currentRect === 0) {
      const sin = Math.sin(rotation * (Math.PI / 180));
      const cos = Math.cos(rotation * (Math.PI / 180));
      const sinLw = sin * (lineThickness / 2);
      const cosLw = cos * (lineThickness / 2);
      const xadd = -sinLw + cosLw;
      const yadd = sinLw + cosLw;

      if (selectedLine === 0) {
        ctx.strokeStyle = "#0000ff";
        ctx.lineWidth = 2;
        ctx.beginPath();

        ctx.moveTo(shapeDimensions.x[0] - xadd, shapeDimensions.y[0] - yadd);
        ctx.lineTo(shapeDimensions.x[1] + yadd, shapeDimensions.y[1] - xadd);
        ctx.lineTo(shapeDimensions.x[1] + xadd, shapeDimensions.y[1] + yadd);
        ctx.lineTo(shapeDimensions.x[0] - yadd, shapeDimensions.y[0] + xadd);
        ctx.lineTo(shapeDimensions.x[0] - xadd, shapeDimensions.y[0] - yadd);

        ctx.stroke();
      } else if (selectedLine === 1) {
        ctx.strokeStyle = "#0000ff";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(shapeDimensions.x[1] + yadd, shapeDimensions.y[1] - xadd);
        ctx.lineTo(shapeDimensions.x[2] + xadd, shapeDimensions.y[2] + yadd);
        ctx.lineTo(shapeDimensions.x[2] - yadd, shapeDimensions.y[2] + xadd);
        ctx.lineTo(shapeDimensions.x[1] - xadd, shapeDimensions.y[1] - yadd);
        ctx.lineTo(shapeDimensions.x[1] + yadd, shapeDimensions.y[1] - xadd);
        ctx.stroke();
      } else if (selectedLine === 2) {
        ctx.strokeStyle = "#0000ff";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(shapeDimensions.x[2] + yadd, shapeDimensions.y[2] - xadd);
        ctx.lineTo(shapeDimensions.x[3] - xadd, shapeDimensions.y[3] - yadd);
        ctx.lineTo(shapeDimensions.x[3] - yadd, shapeDimensions.y[3] + xadd);
        ctx.lineTo(shapeDimensions.x[2] + xadd, shapeDimensions.y[2] + yadd);
        ctx.lineTo(shapeDimensions.x[2] + yadd, shapeDimensions.y[2] - xadd);
        ctx.stroke();
      } else if (selectedLine === 3) {
        ctx.strokeStyle = "#0000ff";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(shapeDimensions.x[3] + xadd, shapeDimensions.y[3] + yadd);
        ctx.lineTo(shapeDimensions.x[0] + yadd, shapeDimensions.y[0] - xadd);
        ctx.lineTo(shapeDimensions.x[0] - xadd, shapeDimensions.y[0] - yadd);
        ctx.lineTo(shapeDimensions.x[3] - yadd, shapeDimensions.y[3] + xadd);
        ctx.lineTo(shapeDimensions.x[3] + xadd, shapeDimensions.y[3] + yadd);
        ctx.stroke();
      }

      ctx.stroke();
    }
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");
    // clear the canvas area before rendering the coordinates held in state
    ctx.clearRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);

    const workingShapes = Array.isArray(shape) ? shape : [shape];
    const workingColours = Array.isArray(rectColour)
      ? rectColour
      : [rectColour];
    workingShapes.forEach((ws, idx) => {
      let newShape = Array.isArray(shape)
        ? { ...ws }
        : rotateShape(ws, rotation);
      newShape = positionShape(newShape, aspectRatio, testAreaDimensions);
      drawRect(ctx, newShape, workingColours[idx]);
    });

    ctx.beginPath();
    ctx.lineWidth = crossThickness;
    ctx.shadowBlur = 0;
    ctx.strokeStyle = "#000000";
    ctx.moveTo(
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] / 2 - parseInt(crossLength, 10)
    );
    ctx.lineTo(
      testAreaDimensions[0] / 2,
      testAreaDimensions[1] / 2 + parseInt(crossLength, 10)
    );
    ctx.moveTo(
      testAreaDimensions[0] / 2 - parseInt(crossLength, 10),
      testAreaDimensions[1] / 2
    );
    ctx.lineTo(
      testAreaDimensions[0] / 2 + parseInt(crossLength, 10),
      testAreaDimensions[1] / 2
    );

    ctx.stroke();
  });

  return (
    <div className="App-canvas-container">
      <canvas
        className="App-canvas"
        ref={canvasRef}
        width={testAreaDimensions[0]}
        height={testAreaDimensions[1]}
      ></canvas>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lineThickness: parseInt(
    state.profiles[state.activeProfile].lineThickness,
    10
  ),
  crossThickness: parseInt(
    state.profiles[state.activeProfile].crossThickness,
    10
  ),
  crossLength: parseInt(state.profiles[state.activeProfile].crossLength, 10),
});

export default connect(mapStateToProps)(Shape);
