import React from "react";
import { connect } from "react-redux";
import { Col, Form, FormGroup, FormText, Label, Input } from "reactstrap";
import RangeSlider from "react-bootstrap-range-slider";

import {
  setWordTimerTestLength,
  setWordTimerFontSize,
  setWordTimerActiveText,
} from "../actions/actions";

import "./WordTimerSettings.css";

function WordTimerSettings(props) {
  const {
    fontSize,
    testLength,
    setFontSize,
    setTestLength,
    textContent,
    activeText,
    setActiveText,
  } = props;
  return (
    <div className="profiles">
      <h4>Word timer settings</h4>
      <Form>
        <FormGroup row>
          <Label for="fontSize" xs={4}>
            Font Size
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="fontSize"
              name="fontSize"
              min={0.9}
              max={11}
              step={0.01}
              value={fontSize}
              onChange={(e) => setFontSize(e.target.value)}
            />
          </Col>
          <Col xs={4}>
            <FormText>Font size {fontSize}em</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="testLength" xs={4}>
            Test Length
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="testLength"
              name="testLength"
              min={5}
              max={90}
              step={1}
              value={testLength}
              onChange={(e) => setTestLength(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Test length {testLength}s</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={4} for="wtActiveTextSelect">
            Select active text
          </Label>
          <Col xs={4}>
            <Input
              type="select"
              id="wtActiveTextSelect"
              name="wtActiveTextSelect"
              defaultValue={activeText}
              onChange={(e) => setActiveText(e.target.value)}
            >
              {[...Array(textContent.length)].map((_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}
const mapStateToProps = (state) => ({
  textContent: state.textContent,
  testLength: state.profiles[state.activeProfile].wordTimer.testLength,
  fontSize: state.profiles[state.activeProfile].wordTimer.fontSize,
  activeText: state.profiles[state.activeProfile].wordTimer.activeText,
});
const mapDispatchToProps = (dispatch, value) => ({
  setTestLength: (value) => dispatch(setWordTimerTestLength(value)),
  setFontSize: (value) => dispatch(setWordTimerFontSize(value)),
  setActiveText: (value) => dispatch(setWordTimerActiveText(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WordTimerSettings);
