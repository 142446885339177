import React, { useState } from "react";
import { Container, Row, Col, Nav, Navbar } from "reactstrap";
import "./DevPath.css";
import TextContainer from "../Tests/TextContainer";
import TestContainer from "../Tests/TestContainer";
import AmslerGrid from "../Tests/AmslerGrid";
import Distortion from "../Tests/Distortion";
import DisparitySquare from "../Tests/DisparitySquare";
import FixationDisparity from "../Tests/FixationDisparity";
import FourDots from "../Tests/FourDots";
import HalfRect from "../Tests/HalfRect";
import ImageSize from "../Tests/ImageSize";
import NearAcuity from "../Tests/NearAcuity";
import NearFarRock from "../Tests/NearFarRock";
import Stereo from "../Tests/Stereo";
import WordTimer from "../Tests/WordTimer";
import Game2 from "../Game2";
import Report from "../Report";
import Settings from "../Settings/Settings";
function DevPath() {
  const [viewMode, setViewMode] = useState("Half rect");

  const options = {
    Settings: "Configuration",
    Text: "Text",
    Test: "Test",
    "Word timer": "Word timer",
    "Near acuity": "Near acuity",
    "Disparity square": "Disparity square",
    "Fixation disparity": "Fixation disparity",
    "Amsler grid": "Amsler grid",
    "Four dots": "Four dots",
    "Near far rock": "Near far rock",
    Distortion: "Distortion",
    /*    ImageSize: "Image size (Aniseikonia)", */
    "Half rect": "Image size half square",
    Stereo: "Stereo",
    Game: "Game",
    Report: "Report",
  };

  return (
    <Container fluid>
      <main className="DevPath-main">
        <Navbar color="faded" light>
          <Nav navbar>
            <ol className="menuList">
              {Object.keys(options).map((option) => (
                <li
                  tabIndex="1"
                  className="menuItem"
                  key={option}
                  onClick={() => setViewMode(option)}
                >
                  {options[option]}
                </li>
              ))}
            </ol>
          </Nav>
        </Navbar>
        <Row noGutters>
          <Col xs={12}>
            <div className="mainBlock">
              {viewMode === "Settings" && (
                <Settings
                  profiles
                  configuration
                  testsettings
                  nearacuity
                  wordtimer
                  nearfar
                  text
                />
              )}
              {viewMode === "Game" && <Game2 />}
              {viewMode === "Report" && <Report />}
              {viewMode === "Test" && <TestContainer />}
              {viewMode === "Half rect" && <HalfRect />}
              {viewMode === "Text" && <TextContainer />}
              {viewMode === "Word timer" && <WordTimer />}
              {viewMode === "Near acuity" && <NearAcuity />}
              {viewMode === "Disparity square" && <DisparitySquare />}
              {viewMode === "Fixation disparity" && <FixationDisparity />}
              {viewMode === "Amsler grid" && <AmslerGrid />}
              {viewMode === "Four dots" && <FourDots />}
              {viewMode === "Near far rock" && <NearFarRock />}
              {viewMode === "Distortion" && <Distortion />}
              {viewMode === "ImageSize" && <ImageSize />}
              {viewMode === "Stereo" && <Stereo />}
            </div>
          </Col>
        </Row>
      </main>
    </Container>
  );
}

export default DevPath;
