export const SET_BACKGROUND_COLOUR = "setBackgroundColour";
export const SET_RED_COLOUR = "setRedColour";
export const SET_GREEN_COLOUR = "setGreenColour";
export const SET_RED_LUMINANCE = "setRedLuminance";
export const SET_GREEN_LUMINANCE = "setGreenLuminance";
export const SET_TEST_AREA_DIMENSIONS = "setTestAreaDimensions";
export const SET_SHAPE_DIMENSIONS = "setShapeDimensions";
export const SET_LINE_THICKNESS = "setLineThickness";
export const SET_CROSS_THICKNESS = "setCrossThickness";
export const SET_CROSS_LENGTH = "setCrossLength";
export const SET_SPEED = "setSpeed";
export const SET_ASPECT_RATIO = "setAspectRatio";
export const SET_ROTATION = "setRotation";
export const SET_REFERENCE_COLOUR = "setReferenceColour";
export const SET_ADJUSTMENT_MODE = "setAdjustmentMode";
export const SET_TEXT_WIDTH = "setTextWidth";
export const SET_TEXT_ACTIVE_TEXT = "setActiveText";
export const SET_TEXT_CONTENT = "setTextContent";
export const SET_TEXT_RANDOMISED = "setTextRandomised";
export const SET_TEXT_RED_OPACITY = "setTextRedOpacity";
export const SET_TEXT_GREEN_OPACITY = "setTextGreenOpacity";
export const SET_TEXT_FONT_SIZE = "setTextFontSize";
export const SET_TEXT_TEST_TYPE = "setTextTestType";
export const SET_TEXT_RED_WORD_COLOUR = "setTextRedWordColour";
export const SET_TEXT_GREEN_WORD_COLOUR = "setTextGreenWordColour";
export const SET_TEXT_RED_WORD_OPACITY = "setTextRedWordOpacity";
export const SET_TEXT_GREEN_WORD_OPACITY = "setTextGreenWordOpacity";
export const SET_ACTIVE_PROFILE = "setActiveProfile";
export const ADD_PROFILE = "addProfile";
export const SET_NEAR_ACUITY_INITIAL_SIZE = "setNearAcuityInitialSize";
export const SET_NEAR_ACUITY_ITERATIONS = "setNearAcuityIterations";
export const SET_NEAR_ACUITY_DIVIDER = "setNearAcuityDivider";
export const SET_WORD_TIMER_FONT_SIZE = "setWordTimerFontSize";
export const SET_WORD_TIMER_TEST_LENGTH = "setWordTimerTestLength";
export const SET_WORD_TIMER_ACTIVE_TEXT = "setWordTimerActiveText";
export const SET_NEAR_FAR_FONT_SIZE = "setNearFarFontSize";
export const SET_NEAR_FAR_FONT_FAMILY = "setNearFarFontFamily";
export const SET_NEAR_FAR_FONT_STRETCH = "setNearFarFontStretch";
export const SET_NEAR_FAR_FONT_STYLE = "setNearFarFontStyle";
export const SET_SITE = "setSite";
export const SET_USER = "setUser";
export const SET_BOOK_MODE = "setBookMode";

type FullColour = {
  r: number;
  g: number;
  b: number;
  a: number;
};

type Dimension = [number, number];

export function setBackgroundColour(colour: FullColour) {
  return { type: SET_BACKGROUND_COLOUR, value: colour };
}

export function setRedColour(colour: FullColour) {
  return { type: SET_RED_COLOUR, value: colour };
}

export function setGreenColour(colour: FullColour) {
  return { type: SET_GREEN_COLOUR, value: colour };
}

export function setRedLuminance(luminance: number) {
  return { type: SET_RED_LUMINANCE, value: luminance };
}

export function setGreenLuminance(luminance: number) {
  return { type: SET_GREEN_LUMINANCE, value: luminance };
}

export function setTextRedWordColour(colour: FullColour) {
  return { type: SET_TEXT_RED_WORD_COLOUR, value: colour };
}

export function setTextGreenWordColour(colour: FullColour) {
  return { type: SET_TEXT_GREEN_WORD_COLOUR, value: colour };
}

export function setTextRedWordOpacity(opacity: number) {
  return { type: SET_TEXT_RED_WORD_OPACITY, value: opacity };
}

export function setTextGreenWordOpacity(opacity: number) {
  return { type: SET_TEXT_GREEN_WORD_OPACITY, value: opacity };
}

export function setSpeed(speed: number) {
  return { type: SET_SPEED, value: speed };
}

export function setLineThickness(value: number) {
  return { type: SET_LINE_THICKNESS, value };
}

export function setCrossThickness(value: number) {
  return { type: SET_CROSS_THICKNESS, value };
}

export function setCrossLength(value: number) {
  return { type: SET_CROSS_LENGTH, value };
}

export function setAspectRatio(value: number) {
  return { type: SET_ASPECT_RATIO, value };
}

export function setRotation(value: string) {
  let numVal = parseInt(value, 10);
  if (isNaN(numVal)) {
    numVal = 0;
  }
  return { type: SET_ROTATION, value: numVal };
}

export function setTestAreaDimensions(dimensions: Dimension) {
  return { type: SET_TEST_AREA_DIMENSIONS, value: dimensions };
}

export function setShapeDimensions(dimensions: Dimension) {
  return { type: SET_SHAPE_DIMENSIONS, value: dimensions };
}

export function setReferenceColour(colour: FullColour) {
  return { type: SET_REFERENCE_COLOUR, value: colour };
}

export function setAdjustmentMode(adjustmentMode: "by_edge") {
  return { type: SET_ADJUSTMENT_MODE, value: adjustmentMode };
}

export function setTextWidth(textWidth: number) {
  return { type: SET_TEXT_WIDTH, value: textWidth };
}

export function setTextActiveText(activeText: number) {
  return { type: SET_TEXT_ACTIVE_TEXT, value: activeText };
}

export function setTextContent(textContent: string) {
  return { type: SET_TEXT_CONTENT, value: textContent };
}

export function setRandomiseText(textRandomised: boolean) {
  return { type: SET_TEXT_RANDOMISED, value: textRandomised };
}

export function setTextRedOpacity(textRedOpacity: number) {
  return { type: SET_TEXT_RED_OPACITY, value: textRedOpacity };
}

export function setTextGreenOpacity(textGreenOpacity: number) {
  return { type: SET_TEXT_GREEN_OPACITY, value: textGreenOpacity };
}

export function setTextFontSize(textFontSize: string) {
  return { type: SET_TEXT_FONT_SIZE, value: textFontSize };
}

export function setTextTestType(textTestType: "by_word") {
  return { type: SET_TEXT_TEST_TYPE, value: textTestType };
}

export function setActiveProfile(profileName: string) {
  return { type: SET_ACTIVE_PROFILE, value: profileName };
}

export function addProfile(profileName: string) {
  return { type: ADD_PROFILE, value: profileName };
}

export function setNearAcuityInitialSize(size: number) {
  return { type: SET_NEAR_ACUITY_INITIAL_SIZE, value: size };
}

export function setNearAcuityIterations(iterations: number) {
  return { type: SET_NEAR_ACUITY_ITERATIONS, value: iterations };
}

export function setNearAcuityDivider(divider: number) {
  return { type: SET_NEAR_ACUITY_DIVIDER, value: divider };
}

export function setWordTimerTestLength(testLength: number) {
  return { type: SET_WORD_TIMER_TEST_LENGTH, value: testLength };
}

export function setWordTimerActiveText(activeText: number) {
  return { type: SET_WORD_TIMER_ACTIVE_TEXT, value: activeText };
}

export function setWordTimerFontSize(fontSize: number) {
  return { type: SET_WORD_TIMER_FONT_SIZE, value: fontSize };
}

export function setNearFarFontSize(fontSize: number) {
  return { type: SET_NEAR_FAR_FONT_SIZE, value: fontSize };
}

export function setNearFarFontFamily(fontFamily: string) {
  return { type: SET_NEAR_FAR_FONT_FAMILY, value: fontFamily };
}

export function setNearFarFontStretch(fontStretch: number) {
  return { type: SET_NEAR_FAR_FONT_STRETCH, value: fontStretch };
}

export function setNearFarFontStyle(fontStyle: string) {
  return { type: SET_NEAR_FAR_FONT_STYLE, value: fontStyle };
}

export function setSite(site: string) {
  return { type: SET_SITE, value: site };
}

export function setUser(user: string) {
  return { type: SET_USER, value: user };
}

export function setBookMode(
  bookMeta: { bookId: number; section: number } | null
) {
  return { type: SET_BOOK_MODE, value: bookMeta };
}
