import React, { useRef, useEffect } from "react";
import { fullColourHex } from "../utils/Utils";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./Stereo.css";

function Stereo(props) {
  const { testAreaDimensions, redColour, greenColour } = props;
  const canvasRef = useRef(null);

  const drawBlob = (ctx, x, y, radius, colour) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = colour;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = colour;
    ctx.stroke();
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");

    const CIRCLE_MIN = 100;
    const CIRCLE_MAX = 150;
    const BLOB_RADIUS = 6;
    let blobs = [];
    const cx = testAreaDimensions[0] / 2;
    const cy = testAreaDimensions[1] / 2;
    const BLOB_NUMBER = (testAreaDimensions[0] * testAreaDimensions[1]) / 700;
    for (let i = 0; i < BLOB_NUMBER; i++) {
      const x = Math.floor(Math.random() * testAreaDimensions[0]);
      const y = Math.floor(Math.random() * testAreaDimensions[1]);
      const distC = Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - cy, 2));
      blobs.push({ x, y, distC });
    }

    // clear the canvas area before rendering the coordinates held in state
    ctx.clearRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);

    blobs = blobs.sort((blob) => {
      return blob.distC < CIRCLE_MAX && blob.distC > CIRCLE_MIN ? -1 : 1;
    });

    blobs.forEach((blob, idx) => {
      //   const colour = idx & 1 ? redColour : greenColour;
      drawBlob(ctx, blob.x, blob.y, BLOB_RADIUS, "black");
      if (blob.distC < CIRCLE_MAX && blob.distC > CIRCLE_MIN) {
        drawBlob(ctx, blob.x - 10, blob.y, BLOB_RADIUS, redColour);

        drawBlob(ctx, blob.x + 10, blob.y, BLOB_RADIUS, greenColour);
      }
    });
  });

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <div>Stereo</div>
        <canvas
          className="App-canvas"
          ref={canvasRef}
          width={testAreaDimensions[0]}
          height={testAreaDimensions[1]}
        ></canvas>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  redColour: fullColourHex(state.profiles[state.activeProfile].colours.red),
  greenColour: fullColourHex(state.profiles[state.activeProfile].colours.green),
  shapeDimensions: state.profiles[state.activeProfile].dimensions.shape,
  testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea,
});

export default connect(mapStateToProps)(Stereo);
