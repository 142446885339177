import React, { useState, useEffect, useRef } from "react";

const ColourSlider = (props) => {
  const { size, offset, normalise, hueIn, luminance, value, setValue } = props;
  const scaleRow = useRef(null);
  const [scale, setScale] = useState(1);
  const [cursor, setCursor] = useState("0px");
  const [style, setStyle] = useState({
    width: "100%",
    height: 60,
    border: "1px solid darkgrey",
  });

  const KEY_STEP = 0.5;

  function debounce(fn, ms) {
    let timer;
    return (...args) => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
        fn.apply(this, args);
      }, ms);
    };
  }

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, 250);

  React.useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const getRGBFromHex = (hex) => ({
    r: Math.floor(((hex >> 16) & 0xff) * (luminance / 100) + 0.5),
    g: Math.floor(((hex >> 8) & 0xff) * (luminance / 100) + 0.5),
    b: Math.floor((hex & 0xff) * (luminance / 100) + 0.5),
  });

  const getHexStringFromRGB = (rgb) => {
    let r = Math.abs(rgb.r).toString(16);
    r = r.length < 2 ? `0${r}` : r;
    let g = Math.abs(rgb.g).toString(16);
    g = g.length < 2 ? `0${g}` : g;
    let b = Math.abs(rgb.b).toString(16);
    b = b.length < 2 ? `0${b}` : b;
    return `#${r}${g}${b}`;
  };

  const to8Bit = (v) => (v > 255 ? v & 0xff : v < 0 ? 256 - v : v);

  const getRGBTransition = (rgbFrom, rgbTo, howFar) => {
    return {
      r: Math.floor(to8Bit((rgbTo.r - rgbFrom.r) * howFar + rgbFrom.r)),
      g: Math.floor(to8Bit((rgbTo.g - rgbFrom.g) * howFar + rgbFrom.g)),
      b: Math.floor(to8Bit((rgbTo.b - rgbFrom.b) * howFar + rgbFrom.b)),
    };
  };

  const getHexRGBTransition = (hexFrom, hexTo, howFar) => {
    return getHexStringFromRGB(
      getRGBTransition(
        getRGBFromHex(hexFrom),
        getRGBFromHex(hexTo),
        howFar / 100
      )
    );
  };

  useEffect(() => {
    const gradientSteps = [
      0xff0000,
      0xff8000, // <!---
      0xffff00,
      0x80ff00,
      0x00ff00,
      0x00ff80,
      0x00ffff,
      0x0080ff,
      0x0000ff,
      0x8000ff, // <!--- // each step 30 degrees
      0xff00ff,
      0xff0080,
    ];

    const wrapStepIndex = (idx) => {
      if (idx < 0) {
        return idx + gradientSteps.length;
      }
      if (idx > gradientSteps.length) {
        return idx - gradientSteps.length;
      }
      return idx;
    };

    const scaleStep = size / 30;
    console.log(`Scale step ${scaleStep}`);

    let firstStep = wrapStepIndex(
      Math.floor(Math.ceil(offset / 30) - scaleStep / 2)
    );

    const partial = offset % 30;
    let cssGradient = `linear-gradient(to right`;
    if (partial > 0) {
      const firstCol = getHexRGBTransition(
        gradientSteps[wrapStepIndex(firstStep - 1)],
        gradientSteps[firstStep],
        partial / 30
      );
      const lastCol = getHexRGBTransition(
        gradientSteps[wrapStepIndex(firstStep - 1 + size / 30)],
        gradientSteps[wrapStepIndex(firstStep + size / 30)],
        30 - partial / 30
      );
      const stepPercent = 100 * (30 / size);
      const offsetPercent = Math.floor(
        0.5 + (100 * (partial / 30)) / scaleStep
      );
      cssGradient += `, ${firstCol}  0%`;
      for (let i = 0; i <= (size - 1) / 30; i++) {
        const rgb = getRGBFromHex(
          gradientSteps[(firstStep + i) % gradientSteps.length]
        );
        cssGradient += "," + getHexStringFromRGB(rgb) + " ";
        cssGradient +=
          Math.floor(i * stepPercent + 0.5 + offsetPercent).toString() + "%";
      }
      cssGradient += `, ${lastCol} 100%`;
      cssGradient += ")";
    } else {
      const stepPercent = 100 * (30 / size);

      for (let i = 0; i <= size / 30; i++) {
        const rgb = getRGBFromHex(
          gradientSteps[(firstStep + i) % gradientSteps.length]
        );
        cssGradient += "," + getHexStringFromRGB(rgb) + " ";
        cssGradient += Math.floor(i * stepPercent + 0.5).toString() + "%";
      }
      cssGradient += ")";
    }

    const lg = cssGradient;

    setStyle({
      ...style,
      backgroundImage: lg,
    });

    // eslint-disable-next-line
  }, [offset, size, hueIn, luminance]);

  // calculate cursor position
  useEffect(() => {
    let xp;
    //offset: 0, size: 180,

    xp = value - (offset - size / 2);
    if (xp < 0) {
      xp += 360;
    }
    if (offset - size / 2 < 0 && value > offset + size / 2) {
      xp = xp - 360;
    }
    xp /= scale;
    xp += 15;

    //   console.log(`Set cursor ${xp}px`);
    setCursor(`${xp}px`);
  }, [offset, scale, size, value, dimensions.width]);

  useEffect(() => {
    /*    if (scaleRow.current) {
      console.log(`Set scale ${scaleRow.current.offsetWidth}`);
    } */
    setScale(scaleRow.current ? size / scaleRow?.current?.offsetWidth : 1);
  }, [scaleRow, size, dimensions.width]);

  const onStep = (e) => {
    let newValue;

    if (e.key === "ArrowLeft") {
      newValue = value - KEY_STEP;
      if (normalise(newValue) < normalise(offset - size / 2)) {
        newValue = offset - size / 2;
      }
    } else if (e.key === "ArrowRight") {
      newValue = value + KEY_STEP;
      if (normalise(newValue) > normalise(offset + size / 2 - 1)) {
        newValue = offset + size / 2 - 1;
      }
    }

    setValue(newValue ?? 0);
  };

  const setHueFromMouse = (e) => {
    const mouseX = e.clientX ?? 0;
    const debouncedUpdateHue = debounce(function handleHueChange() {
      const bounds = scaleRow?.current?.getBoundingClientRect();

      const xOff = mouseX - (bounds?.left ?? 0);
      // calculate hue
      let hueIn = (offset - size / 2 + xOff * scale) % 360;
      hueIn = hueIn < 0 ? hueIn + 360 : hueIn;
      setValue(hueIn);
    }, 100);
    debouncedUpdateHue(mouseX);
  };

  const onMove = (e) => {
    if (e.buttons === 1) {
      setHueFromMouse(e);
    }
  };

  return (
    <>
      <div
        style={{
          left: cursor,
          zIndex: 10,
          backgroundColor: "transparent",
          position: "absolute",
          width: 6,
          height: 60,
          border: "1px solid black",
        }}
      ></div>
      <div
        tabIndex={0}
        onKeyDown={onStep}
        onMouseMove={onMove}
        onMouseDown={onMove}
        ref={scaleRow}
        style={style}
      ></div>
    </>
  );
};

export default ColourSlider;
