import React from "react";
import { connect } from "react-redux";
import "./Report.css";

function Report(props) {
  const { state } = props;
  return (
    <div className="report">
      <code>Results will appear here</code>
    </div>
  );
}
/* {JSON.stringify(state)}*/
const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(Report);
