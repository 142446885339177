import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiGetBooks, apiSearchBooks, apiSelectBook } from "../utils/Api";
//import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Label, Input } from "reactstrap";
import "./TextSettings.css";

import {
  setTextActiveText as setTextActiveTextRedux,
  setTextContent as setTextContentRedux,
  setTextTestType as setTextTestTypeRedux,
  setRandomiseText as setRandomiseTextRedux,
  setBookMode,
} from "../actions/actions";

function TextTestReadingSettings() {
  const activeText = useSelector(
    (state) => state.profiles[state.activeProfile].text.activeText
  );
  const textContent = useSelector((state) => state.textContent);
  const textTestType = useSelector(
    (state) => state.profiles[state.activeProfile].text.testType
  );
  const randomiseText = useSelector(
    (state) => state.profiles[state.activeProfile].text.randomised
  );
  const bookMode = useSelector(
    (state) => state.profiles[state.activeProfile].text.bookMode
  );

  const [book, setBook] = useState(null);
  const [bookList, setBookList] = useState([]);
  const dispatch = useDispatch();

  const setTextActiveText = (value) => dispatch(setTextActiveTextRedux(value));
  const setTextContent = (value) => dispatch(setTextContentRedux(value));
  const setTextTestType = (value) => dispatch(setTextTestTypeRedux(value));
  const setRandomiseText = (value) => dispatch(setRandomiseTextRedux(value));
  const doSetBookMode = (bookId) => {
    const bookMode = { bookId, section: 0 };
    dispatch(setBookMode(bookMode));
  };
  const [bookSearch, setBookSearch] = useState("");
  const [booksFound, setBooksFound] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);

  useEffect(() => {
    const getBooks = async () => {
      const localBookList = await apiGetBooks();
      setBookList(
        localBookList.map((book) => ({
          ...book,
        }))
      );
    };
    getBooks();
    if (bookMode?.bookId) {
      setBook(parseInt(bookMode.bookId, 10));
    }
  }, [bookMode]);

  const searchBook = async () => {
    setBooksFound([]);
    try {
      const books = await apiSearchBooks(bookSearch);
      setBooksFound([...books]);
      if (books.length > 0) {
        setSelectedBook(books[0]);
      }
    } catch (e) {
      console.log("Error");
    }
  };

  const getSelectedBook = async (book) => {
    try {
      const res = await apiSelectBook(book);
      if (res) {
        doSetBookMode(book.number);
      }
    } catch (e) {
      return false;
    }
  };

  const sanitiseText = (text) => {
    return text
      .replace(/\t/g, " ")
      .replace(/\s+/g, " ")
      .replace(/[\u{0080}-\u{FFFF}]/gu, "");
  };
  /*
  const getBook = async (book) => {
    setSelectedBook(book);
  };
*/
  return (
    <div className="textSettingsBox">
      <h4>Text reading settings</h4>
      <Form>
        <FormGroup row>
          <Col xs={8}>
            <Label for="selectBook">Select a book from our database</Label>
            <Input
              type="select"
              onChange={(e) => {
                setBook(e.target.value);
              }}
              value={book ?? ""}
              id="selectBook"
              name="selectBook"
            >
              <option value="">
                Use preset practice text from the box below
              </option>
              {bookList.map((localBook) => (
                <option key={localBook.title} value={localBook.id}>
                  {localBook.title}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs={4} className="d-flex flex-column justify-content-end">
            <Button
              color="primary"
              className="align-bottom"
              onClick={() => doSetBookMode(book)}
            >
              {!book ? "Practice text" : "Read book"}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs={8}>
            <Label for="searchBook">
              Or search for a book on project gutenberg
            </Label>
            <Input
              type="text"
              onChange={(e) => {
                setBookSearch(e.target.value);
              }}
              id="searchBook"
              name="searchBook"
            ></Input>
          </Col>
          <Col xs={4} className="d-flex flex-column justify-content-end">
            <Button
              color="primary"
              disabled={bookSearch.length < 3}
              onClick={() => searchBook(book)}
            >
              Find
            </Button>
          </Col>
        </FormGroup>
        {booksFound.length > 0 ? (
          <FormGroup row>
            <Col xs={8}>
              <Label for="selectSelectedBook">
                These are your best matches:{" "}
              </Label>
              <Input
                type="select"
                onChange={(e) => {
                  const localBook = booksFound.find(
                    (booksFoundI) => booksFoundI.number === e.target.value
                  );
                  setSelectedBook({ ...localBook });
                }}
                value={selectedBook?.number ?? ""}
                id="selectSelectedBook"
                name="selectSelectedBook"
              >
                {booksFound.map((book) => (
                  <option key={book.number} value={book.number}>
                    {book.title}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={4} className="d-flex flex-column justify-content-end">
              <Button
                color="primary"
                disabled={selectedBook === null}
                onClick={() => getSelectedBook(selectedBook)}
              >
                Select and add
              </Button>
            </Col>
          </FormGroup>
        ) : null}
        {book === "" ? (
          <>
            <FormGroup row>
              <Col xs={8}>
                <Label for="activeTextSelect">
                  Select active practice text block
                </Label>
                <Input
                  type="select"
                  id="activeTextSelect"
                  name="activeTextSelect"
                  defaultValue={activeText}
                  onChange={(e) => setTextActiveText(e.target.value)}
                >
                  {[...Array(textContent.length + 1)].map((_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup>
              <Label for="textContentInput">Test text ({activeText})</Label>
              <Input
                type="textarea"
                rows="8"
                cols="80"
                id="textContentInput"
                name="textContentInput"
                value={
                  textContent.length > parseInt(activeText, 10)
                    ? textContent[activeText]
                    : "Add content here"
                }
                onChange={(e) =>
                  setTextContent({
                    content: sanitiseText(e.target.value),
                    activeText,
                  })
                }
              ></Input>
              <Button
                color="primary"
                disabled={!textContent[activeText]}
                onClick={() => setTextContent({ content: "", activeText })}
              >
                Clear
              </Button>
            </FormGroup>

            <FormGroup row check>
              <Col xs={4}>
                <Label for="randomiseText" check>
                  <Input
                    id="randomiseText"
                    name="randomiseText"
                    type="checkbox"
                    value={true}
                    checked={randomiseText === true}
                    onChange={() => {
                      setRandomiseText(!randomiseText);
                    }}
                  />{" "}
                  Randomise
                </Label>
              </Col>
            </FormGroup>
          </>
        ) : null}
        <FormGroup row>
          <Label xs={5} className="col-form-label col-xs-5">
            Test text colouring
          </Label>
          <Col xs={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="by_column"
                  checked={textTestType === "by_column"}
                  onChange={() => setTextTestType("by_column")}
                />{" "}
                By column
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="by_word"
                  checked={textTestType === "by_word"}
                  onChange={() => setTextTestType("by_word")}
                />
                By word
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="by_tricolour_word"
                  checked={textTestType === "by_tricolour_word"}
                  onChange={() => setTextTestType("by_tricolour_word")}
                />
                By red/green/black text
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

export default TextTestReadingSettings;
