import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiGetBook, apiGetBooks } from "../utils/Api";
import { setBookMode } from "../actions/actions";
import SizeContext from "../Components/FullSizer/fullSizerContext";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import "./TextContainer.css";
import { fullColourHex } from "../utils/Utils";
import FullSizer from "../Components/FullSizer/FullSizer";
import TextSettings from "../Settings/TextSettings";

import styled from "@emotion/styled";
/*
interface ITextBackground {
  width: number;
  noCanvas?: boolean;
  fontSize: number;
}
*/
const TextBackground = styled.div((props) => ({
  width: `${props.width}px`,
  zIndex: 1,
  margin: "auto",
  overflowY: props?.noCanvas ? "scroll" : "hidden",
  position: props?.noCanvas ? "inherit" : "absolute",
  fontSize: `${props.fontSize}em`,
}));
/*
interface ITestTextProps {
  text: string[];
  red: string;
  green: string;
  redOpacity: number;
  greenOpacity: number;
}
*/
const TestText = (props) => {
  const { text, red, green, redOpacity, greenOpacity } = props;
  const colourStyles = [
    { color: red, opacity: redOpacity },
    { color: green, opacity: greenOpacity },
  ];
  return (
    <>
      {text.map((t, idx) => (
        <span key={`txt_${idx}`} style={colourStyles[idx & 1]}>
          {t}{" "}
        </span>
      ))}
    </>
  );
};
/*
interface ITestTripleTextProps {
  text: string[];
  red: string;
  green: string;
  redOpacity: number;
  greenOpacity: number;
}
*/
const TestTripleText = (props) => {
  const { text, red, green, redOpacity, greenOpacity } = props;
  const colourStyles = [
    { color: red, opacity: redOpacity },
    { color: green, opacity: greenOpacity },
    {},
  ];
  return (
    <>
      {text.map((t, idx) => (
        <span key={`txt_${idx}`} style={colourStyles[idx % 3]}>
          {t}{" "}
        </span>
      ))}
    </>
  );
};
/*
interface ITextContainerProps {}
*/
const TextContainer = () => {
  const [showConfig, setShowConfig] = useState(false);
  const canvasRef = useRef(null);
  const dispatch = useDispatch();

  const [testText, setTestText] = useState([]);
  const testAreaDimensions = useSelector((state) => [
    ...state.profiles[state.activeProfile].dimensions.testArea,
  ]);

  testAreaDimensions[0] *= 2;
  testAreaDimensions[1] *= 2;

  const redColour = useSelector((state) => {
    const redCol = { ...state.profiles[state.activeProfile].colours.red };
    const redLuminance = parseInt(
      state.profiles[state.activeProfile].colours.redLuminance,
      10
    );
    redCol.r = Math.floor(redCol.r * (redLuminance / 100));
    redCol.g = Math.floor(redCol.g * (redLuminance / 100));
    redCol.b = Math.floor(redCol.b * (redLuminance / 100));
    return fullColourHex(redCol);
  });
  const greenColour = useSelector((state) => {
    const greenCol = { ...state.profiles[state.activeProfile].colours.green };
    const greenLuminance = parseInt(
      state.profiles[state.activeProfile].colours.greenLuminance,
      10
    );
    greenCol.r = Math.floor(greenCol.r * (greenLuminance / 100));
    greenCol.g = Math.floor(greenCol.g * (greenLuminance / 100));
    greenCol.b = Math.floor(greenCol.b * (greenLuminance / 100));
    return fullColourHex(greenCol);
  });
  const textWidth = useSelector(
    (state) => state.profiles[state.activeProfile].text.width ?? 1
  );
  const textContent = useSelector((state) =>
    state.textContent.length >
    parseInt(state.profiles[state.activeProfile].text.activeText, 10)
      ? state.textContent[state.profiles[state.activeProfile].text.activeText]
      : ""
  );
  const textFontSize = useSelector(
    (state) => state.profiles[state.activeProfile].text.fontSize
  );
  const textRedOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.redOpacity
  );
  const textGreenOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.greenOpacity
  );
  const textTestType = useSelector(
    (state) => state.profiles[state.activeProfile].text.testType
  );
  const redWordColour = useSelector((state) => {
    const redCol = { ...state.profiles[state.activeProfile].colours.red };
    const redLuminance = parseInt(
      state.profiles[state.activeProfile].colours.redLuminance,
      10
    );
    redCol.a = redLuminance / 100;
    /*   redCol.r = Math.floor(redCol.r * (redLuminance / 100));
    redCol.g = Math.floor(redCol.g * (redLuminance / 100));
    redCol.b = Math.floor(redCol.b * (redLuminance / 100)); */
    return fullColourHex(redCol);
  });
  const greenWordColour = useSelector((state) => {
    const greenCol = { ...state.profiles[state.activeProfile].colours.green };
    const greenLuminance = parseInt(
      state.profiles[state.activeProfile].colours.greenLuminance,
      10
    );
    greenCol.a = greenLuminance / 100;
    /*   greenCol.r = Math.floor(greenCol.r * (greenLuminance / 100));
    greenCol.g = Math.floor(greenCol.g * (greenLuminance / 100));
    greenCol.b = Math.floor(greenCol.b * (greenLuminance / 100)); */
    return fullColourHex(greenCol);
  });
  const redWordOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.redWordOpacity
  );
  const greenWordOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.greenWordOpacity
  );
  const crossThickness = useSelector(
    (state) => state.profiles[state.activeProfile].crossThickness
  );
  const crossLength = useSelector(
    (state) => state.profiles[state.activeProfile].crossLength
  );
  const textRandomised = useSelector(
    (state) => state.profiles[state.activeProfile].text.randomised
  );
  const bookMode = useSelector(
    (state) => state.profiles[state.activeProfile].text.bookMode
  );
  const [currentBook, setCurrentBook] = useState("");

  const toggle = () => setShowConfig(!showConfig);
  const expandText = (text) => {
    let taSeed;
    if (textRandomised) {
      taSeed = text.trim().split(/\W+/);
    } else {
      taSeed = text.trim().split(/\s+/);
    }
    const ta = [];
    if (taSeed.length === 0) {
      return ta;
    }
    let charCount = 0;
    let idx = 0;
    while (charCount < 20000) {
      if (textRandomised) {
        ta.push(taSeed[Math.floor(Math.random() * taSeed.length)]);
      } else {
        ta.push(taSeed[idx++ % taSeed.length]);
      }
      charCount += 1 + ta[ta.length - 1].length;
    }
    return ta;
  };
  /*
  interface IBook {
    id: string;
    title: string;
  }
*/
  useEffect(() => {
    const getBooks = async (bookId) => {
      const books = await apiGetBooks();
      const myBook = books.find(
        (book) => parseInt(book.id, 10) === parseInt(bookId, 10)
      );
      if (myBook) {
        setCurrentBook(`${myBook.title} page ${bookMode.section + 1}`);
      } else {
        dispatch(setBookMode(null));
      }
    };

    if (bookMode?.bookId) {
      getBooks(bookMode.bookId);
    }
  }, [bookMode, dispatch]);

  const prevPage = () => {
    dispatch(setBookMode({ ...bookMode, section: bookMode.section - 1 }));
  };

  const nextPage = () => {
    dispatch(setBookMode({ ...bookMode, section: bookMode.section + 1 }));
  };

  const setPage = (e) => {
    let page = parseInt(e?.target?.value ?? 1, 10);
    if (page < 1) {
      page = 1;
    }
    if (page > 299) {
      page = 299;
    }
    dispatch(setBookMode({ ...bookMode, section: page - 1 }));
  };

  useEffect(() => {
    const fillBookPage = async () => {
      const text = await apiGetBook(bookMode.bookId, bookMode.section);
      setTestText(text.trim().split(/\s+/));
    };
    if (bookMode?.bookId) {
      fillBookPage();
    } else {
      setTestText(expandText(textContent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textContent, textRandomised, bookMode]);

  const fillVerticalRect = (ctx, xPos, width, colour) => {
    ctx.fillStyle = colour;
    ctx.fillRect(xPos, 0, width, testAreaDimensions[1]);
  };

  useEffect(() => {
    const canvasObj = canvasRef.current;
    if (canvasObj !== null) {
      const ctx = canvasObj.getContext("2d");
      // clear the canvas area before rendering the coordinates held in state
      if (ctx) {
        ctx.clearRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);

        //drawRect(ctx, shapeDimensions, rectColour);

        for (let i = 0; i < testAreaDimensions[0]; i += textWidth * 2) {
          ctx.globalAlpha = textRedOpacity;
          fillVerticalRect(ctx, i, textWidth, redColour);
          ctx.globalAlpha = textGreenOpacity;
          fillVerticalRect(
            ctx,
            i + parseInt(textWidth, 10),
            textWidth,
            greenColour
          );
        }
        ctx.globalAlpha = 1;
        ctx.beginPath();
        ctx.lineWidth = crossThickness;
        ctx.strokeStyle = "#000000FF";
        ctx.moveTo(
          testAreaDimensions[0] / 2,
          testAreaDimensions[1] / 2 - parseInt(crossLength, 10)
        );
        ctx.lineTo(
          testAreaDimensions[0] / 2,
          testAreaDimensions[1] / 2 + parseInt(crossLength, 10)
        );
        ctx.moveTo(
          testAreaDimensions[0] / 2 - parseInt(crossLength, 10),
          testAreaDimensions[1] / 2
        );
        ctx.lineTo(
          testAreaDimensions[0] / 2 + parseInt(crossLength, 10),
          testAreaDimensions[1] / 2
        );

        ctx.stroke();
      }
    }
  });

  const textStyle = {
    backgroundImage: `repeating-linear-gradient(to right,${redColour},${redColour} ${textWidth}px,${greenColour} ${textWidth}px,${greenColour} ${
      textWidth * 2
    }px)`,
  };
  console.log("Render");

  // @ts-ignore
  const onKeyDownInText = (e) => {
    // eslint-disable-next-line no-debugger
    debugger;
  };

  return (
    <>
      <Row className="mt-4">
        <Col xs={4}>
          {currentBook ? (
            <ButtonGroup>
              <Button
                disabled={bookMode.section === 0}
                color="info"
                size="sm"
                onClick={prevPage}
              >
                &lt;
              </Button>
              <Input
                type="number"
                style={{ width: 80 }}
                value={bookMode.section + 1}
                onChange={(e) => setPage(e)}
              ></Input>
              <Button
                color="info"
                disabled={bookMode.section === 299}
                size="sm"
                onClick={nextPage}
              >
                &gt;
              </Button>
            </ButtonGroup>
          ) : null}
        </Col>
        <Col xs={4} className="justify-content-center">
          <h2 className="text-center">Red green text</h2>
        </Col>
        <Col className="text-right" xs={{ size: 2, order: 2, offset: 2 }}>
          <Button color="primary" size="sm" onClick={toggle}>
            Options
          </Button>
        </Col>
      </Row>
      {currentBook ? (
        <Row className="mt-1">
          <Col xs={12} className="justify-content-center">
            <h6 className="text-center">{currentBook}</h6>
          </Col>
        </Row>
      ) : null}{" "}
      <Row className="mt-3">
        <Col xs={12}>
          <FullSizer>
            <SizeContext.Consumer>
              {(fullScreen) => (
                <div tabIndex={1} onKeyDown={() => console.log("Key down")}>
                  {textTestType === "by_column" ? (
                    <div
                      style={{
                        margin: "auto",
                        width: `${testAreaDimensions[0]}px`,
                      }}
                    >
                      <TextBackground
                        width={testAreaDimensions[0]}
                        fontSize={textFontSize}
                      >
                        <span className="testText" style={textStyle}>
                          {testText.join(" ")}
                        </span>
                      </TextBackground>
                      <canvas
                        width={`${testAreaDimensions[0]}px`}
                        height={`${testAreaDimensions[0]}px`}
                        ref={canvasRef}
                        className="canvasForeground"
                      />
                    </div>
                  ) : null}
                  {textTestType === "by_word" ? (
                    <>
                      <TextBackground
                        width={testAreaDimensions[0]}
                        noCanvas
                        fontSize={textFontSize}
                        onKeyDown={(e) => onKeyDownInText(e)}
                      >
                        <TestText
                          text={testText}
                          red={redWordColour}
                          green={greenWordColour}
                          redOpacity={redWordOpacity}
                          greenOpacity={greenWordOpacity}
                        />
                      </TextBackground>
                      <canvas
                        style={{ display: "none" }}
                        width={`${testAreaDimensions[0]}px`}
                        height={`${testAreaDimensions[0]}px`}
                        ref={canvasRef}
                        className="canvasForeground"
                      />
                    </>
                  ) : null}
                  {textTestType === "by_tricolour_word" ? (
                    <>
                      <TextBackground
                        width={testAreaDimensions[0]}
                        noCanvas
                        fontSize={textFontSize}
                      >
                        <TestTripleText
                          text={testText}
                          red={redWordColour}
                          green={greenWordColour}
                          redOpacity={redWordOpacity}
                          greenOpacity={greenWordOpacity}
                        />
                      </TextBackground>
                      <canvas
                        style={{ display: "none" }}
                        width={`${testAreaDimensions[0]}px`}
                        height={`${testAreaDimensions[0]}px`}
                        ref={canvasRef}
                        className="canvasForeground"
                      />
                    </>
                  ) : null}
                  {currentBook ? (
                    <ButtonGroup className={fullScreen ? "floatBottom" : ""}>
                      <Button
                        disabled={bookMode.section === 0}
                        color="info"
                        size="sm"
                        onClick={prevPage}
                      >
                        &lt;
                      </Button>
                      <Input
                        type="number"
                        style={{ width: 80 }}
                        value={bookMode.section + 1}
                        onChange={(e) => setPage(e)}
                      ></Input>
                      <Button
                        color="info"
                        disabled={bookMode.section === 299}
                        size="sm"
                        onClick={nextPage}
                      >
                        &gt;
                      </Button>
                    </ButtonGroup>
                  ) : null}
                </div>
              )}
            </SizeContext.Consumer>
          </FullSizer>
        </Col>
      </Row>
      <Modal
        isOpen={showConfig}
        fade={false}
        toggle={toggle}
        className="modal-lg"
      >
        <ModalHeader toggle={toggle}>Text settings</ModalHeader>
        <ModalBody>
          <TextSettings />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TextContainer;
