import React, { useRef, useState, useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import "./AmslerGrid.css";

const GRID_GAP = 40;
const DISTORTION_VAL = 0.2;


function AmslerGrid(props) {
    const { testAreaDimensions } = props;
    const canvasRef = useRef(null);
    const [distortionHeight, setDistortionHeight] = useState(-1);

    const drawBlob = (ctx, x, y, radius, colour) => {
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = colour;
        ctx.fill();
        ctx.lineWidth = 1;
        ctx.strokeStyle = colour;
        ctx.stroke();
    }

    const flipDistortionHeight = () => {
        if (distortionHeight === -1) {
            setDistortionHeight((Math.random() * 2) + 2);
        } else {
            setDistortionHeight(-1);
        }
    }

    const constructGrid = () => {
        const splashX = ((testAreaDimensions[0] * .1) / GRID_GAP) + (Math.random() * testAreaDimensions[0] * .8 / GRID_GAP);
        const splashY = ((testAreaDimensions[1] * .1) / GRID_GAP) + (Math.random() * testAreaDimensions[1] * .8 / GRID_GAP);

        const splashHeight = distortionHeight;

        const grid = [];
        for (let y = 0; y <= Math.floor(Math.ceil(testAreaDimensions[1] / GRID_GAP)); y++) {
            for (let x = 0; x <= Math.floor(Math.ceil(testAreaDimensions[0] / GRID_GAP)); x++) {
                const splashDist = Math.sqrt(Math.pow(x - splashX, 2) + Math.pow(y - splashY, 2));
                if (splashDist < splashHeight) {
                    const offset = splashHeight - splashDist;
                    const scaledOffset = (Math.sin(offset * ((Math.PI / 2) / splashHeight))) * DISTORTION_VAL;

                    const xOffs = x - splashX > 0 ? scaledOffset : -scaledOffset;
                    const yOffs = y - splashY > 0 ? scaledOffset : -scaledOffset;

                    //                    console.log(`${x} ${y} ${xOffs} ${yOffs} ${splashHeight} ${offset} ${scaledOffset}`);
                    grid.push([x + xOffs, y + yOffs]);
                }
                else {
                    grid.push([x, y]);
                }
            }
        }
        return grid;
    }

    useEffect(() => {
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext("2d");
        ctx.clearRect(0, 0, testAreaDimensions[0], testAreaDimensions[1]);
        drawBlob(ctx, testAreaDimensions[0] / 2, testAreaDimensions[1] / 2, 10, 'black');
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.shadowBlur = 0;
        ctx.strokeStyle = 'black';
        const grid = constructGrid();

        const xWidth = 1 + (Math.ceil(testAreaDimensions[0] / GRID_GAP));
        const yWidth = 1 + (Math.ceil(testAreaDimensions[1] / GRID_GAP));
        grid.forEach((coordinates, idx) => {
            if (idx % xWidth === 0) {
                ctx.moveTo(coordinates[0] * GRID_GAP, coordinates[1] * GRID_GAP);
            } else {
                ctx.lineTo(coordinates[0] * GRID_GAP, coordinates[1] * GRID_GAP);
            }
        });
        for (let x = 0; x < xWidth; x++) {
            for (let y = 0; y < yWidth; y++) {
                const coordinates = grid[x + (y * xWidth)];
                if (y === 0) {
                    ctx.moveTo(coordinates[0] * GRID_GAP, coordinates[1] * GRID_GAP);
                } else {
                    ctx.lineTo(coordinates[0] * GRID_GAP, coordinates[1] * GRID_GAP);
                }
            }
        }

        ctx.stroke();
    });


    return (<Row className="justify-content-center">
        <Col xs="auto">
            <h4>Amsler grid</h4>
            <canvas
                className="App-canvas"
                ref={canvasRef}
                width={testAreaDimensions[0] + 1}
                height={testAreaDimensions[1] + 1}
            ></canvas>
        </Col><Col xs={2}><Row><Button color='primary' onClick={flipDistortionHeight}>{distortionHeight === -1 ? 'Show' : 'Hide'} distortion</Button></Row></Col></Row>)
}

const mapStateToProps = (state) => ({
    testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea.map(x => parseInt(x, 10))
});

export default connect(mapStateToProps)(AmslerGrid);