import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Col, Form, FormGroup, Label, Input } from "reactstrap";

import "./TestSettings.css";

import {
  setSpeed,
  setShapeDimensions,
  setRotation,
  setReferenceColour,
  setAdjustmentMode,
} from "../actions/actions";

function TestSettings() {
  const rotation = useSelector((state) =>
    parseFloat(state.profiles[state.activeProfile].rotation)
  );
  const referenceColour = useSelector(
    (state) => state.profiles[state.activeProfile].referenceColour
  );
  const adjustmentMode = useSelector(
    (state) => state.profiles[state.activeProfile].adjustmentMode
  );
  const speed = useSelector(
    (state) => state.profiles[state.activeProfile].speed
  );
  const shapeDimensions = useSelector(
    (state) => state.profiles[state.activeProfile].dimensions.shape
  );

  const dispatch = useDispatch();
  const doSetRotation = (value) => dispatch(setRotation(value));
  const doSetReferenceColour = (value) => dispatch(setReferenceColour(value));
  const doSetAdjustmentMode = (value) => dispatch(setAdjustmentMode(value));
  const doSetShapeDimensions = (value) => dispatch(setShapeDimensions(value));
  const doSetSpeed = (value) => dispatch(setSpeed(value));

  return (
    <div className="settingsBox">
      <h4>Test Settings</h4>
      <Form>
        <FormGroup row>
          <Label sm={4} for="rotationInput">
            Rotation (deg)
          </Label>
          <Col sm={4}>
            <Input
              id="rotationInput"
              name="rotationInput"
              value={rotation}
              onChange={(e) => doSetRotation(e.target.value)}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <legend className="col-form-label col-sm-4">Adjustment mode</legend>
          <Col sm={8}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="by_edge"
                  checked={adjustmentMode === "by_edge"}
                  onChange={() => doSetAdjustmentMode("by_edge")}
                />
                By edge
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="by_scale"
                  checked={adjustmentMode === "by_scale"}
                  onChange={() => doSetAdjustmentMode("by_scale")}
                />
                By scale and position
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <legend className="col-form-label col-sm-4">Reference colour</legend>
          <Col sm={8}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="red"
                  checked={referenceColour === "red"}
                  onChange={() => doSetReferenceColour("red")}
                />
                Red
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value="green"
                  checked={referenceColour === "green"}
                  onChange={() => doSetReferenceColour("green")}
                />
                Green
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4} for="speedInput">
            Switching delay(ms)
          </Label>
          <Col sm={4}>
            <Input
              id="speedInput"
              name="speedInput"
              value={speed}
              onChange={(e) => doSetSpeed(e.target.value)}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} for="sizeInput">
            Shape size in pixels
          </Label>
          <Col sm={4}>
            <Input
              id="sizeInput"
              name="sizeInput"
              value={shapeDimensions[0]}
              onChange={(e) =>
                doSetShapeDimensions([e.target.value, e.target.value])
              }
            ></Input>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

export default TestSettings;
