import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { fullColourHex } from "../utils/Utils";
import ColourPicker from "../Components/ColourPicker/ColourPicker";

import {
  setBackgroundColour,
  setRedColour,
  setGreenColour,
  setSpeed,
  setAspectRatio,
  setTestAreaDimensions,
  setShapeDimensions,
  setLineThickness,
  setCrossThickness,
  setCrossLength,
} from "../actions/actions";

function Configuration(props) {
  const dispatch = useDispatch();
  const backgroundColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.background
  );
  const doSetBackgroundColour = (value) => dispatch(setBackgroundColour(value));

  const {
    shapeDimensions,
    setShapeDimensions,
    testAreaDimensions,
    setTestAreaDimensions,
    redColour,
    setRedColour,
    greenColour,
    setGreenColour,
    aspectRatio,
    setAspectRatio,
    speed,
    setSpeed,
    lineThickness,
    setLineThickness,
    crossThickness,
    setCrossThickness,
    crossLength,
    setCrossLength,
  } = props;

  const [pickerOn, setPickerOn] = useState("");

  const closeColourPicker = () => {
    setPickerOn("");
  };

  return (
    <div className="settingsBox">
      <div>
        <h4>Global configuration</h4>
        <Form>
          <FormGroup row>
            <Label sm={4}>Aspect ratio</Label>
            <Col sm={1}>
              <Button
                color="primary"
                onClick={() =>
                  setAspectRatio((parseFloat(aspectRatio) + 0.01).toFixed(2))
                }
              >
                <FontAwesomeIcon icon={faPlusSquare} />
              </Button>
            </Col>
            <Col sm={1}>
              <Button
                color="primary"
                onClick={() =>
                  setAspectRatio((parseFloat(aspectRatio) - 0.01).toFixed(2))
                }
              >
                <FontAwesomeIcon icon={faMinusSquare} />
              </Button>
            </Col>
            <Col sm={6}>
              <span>
                &nbsp;{aspectRatio} :{" "}
                {(shapeDimensions[0] * aspectRatio).toFixed(0)}px x{" "}
                {shapeDimensions[1]}px
              </span>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="backgroundColourPicker">
              Background colour
            </Label>
            &nbsp;
            <Col sm={4}>
              <div style={{ position: "absolute", zIndex: 10, marginLeft: 80 }}>
                <ColourPicker
                  pickerOn={pickerOn === "background"}
                  initialColour={backgroundColour}
                  setPickedColour={(colour, e) => {
                    doSetBackgroundColour(colour.rgb);
                  }}
                  closeColourPicker={closeColourPicker}
                />
              </div>
              <Button
                id="backgroundColourPicker"
                name="backgroundColourPicker"
                onClick={() => setPickerOn("background")}
                className="pickerButton"
              >
                <div
                  style={{
                    backgroundColor: fullColourHex(backgroundColour, false),
                  }}
                >
                  &nbsp;
                </div>
              </Button>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="redColourPicker">
              Red colour
            </Label>
            &nbsp;
            <Col sm={4}>
              <div style={{ position: "absolute", zIndex: 10, marginLeft: 80 }}>
                <ColourPicker
                  pickerOn={pickerOn === "red"}
                  initialColour={redColour}
                  setPickedColour={(colour, e) => {
                    setRedColour(colour.rgb);
                  }}
                  closeColourPicker={closeColourPicker}
                />
              </div>
              <Button
                id="redColourPicker"
                name="redColourPicker"
                onClick={() => setPickerOn("red")}
                className="pickerButton"
              >
                <div style={{ backgroundColor: fullColourHex(redColour) }}>
                  &nbsp;
                </div>
              </Button>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="greenColourPicker">
              Green colour {pickerOn}
            </Label>
            &nbsp;
            <Col sm={4}>
              <div style={{ position: "absolute", zIndex: 10, marginLeft: 80 }}>
                <ColourPicker
                  pickerOn={pickerOn === "green"}
                  initialColour={greenColour}
                  setPickedColour={(colour) => setGreenColour(colour.rgb)}
                  closeColourPicker={closeColourPicker}
                />
              </div>
              <Button
                id="greenColourPicker"
                name="greenColourPicker"
                onClick={() => setPickerOn("green")}
                className="pickerButton"
              >
                <div style={{ backgroundColor: fullColourHex(greenColour) }}>
                  &nbsp;
                </div>
              </Button>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="speedInput">
              Switching delay(ms)
            </Label>
            <Col sm={4}>
              <Input
                id="speedInput"
                name="speedInput"
                value={speed}
                onChange={(e) => setSpeed(e.target.value)}
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="sizeInput">
              Shape size in pixels
            </Label>
            <Col sm={4}>
              <Input
                id="sizeInput"
                name="sizeInput"
                value={shapeDimensions[0]}
                onChange={(e) =>
                  setShapeDimensions([e.target.value, e.target.value])
                }
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="testAreaInput">
              Test area size in pixels
            </Label>
            <Col sm={4}>
              <Input
                id="testAreaInput"
                name="testAreaInput"
                value={testAreaDimensions[0]}
                onChange={(e) =>
                  setTestAreaDimensions([e.target.value, e.target.value])
                }
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="lineThickness">
              Line thickness
            </Label>
            <Col sm={4}>
              <Input
                id="lineThickness"
                name="lineThickness"
                value={lineThickness}
                onChange={(e) => setLineThickness(e.target.value)}
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="crossThickness">
              Cross thickness
            </Label>
            <Col sm={4}>
              <Input
                id="crossThickness"
                name="crossThickness"
                value={crossThickness}
                onChange={(e) => setCrossThickness(e.target.value)}
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} for="crossLength">
              Cross length
            </Label>
            <Col sm={4}>
              <Input
                id="crossLength"
                name="crossLength"
                value={crossLength}
                onChange={(e) => setCrossLength(e.target.value)}
              ></Input>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  shapeDimensions: state.profiles[state.activeProfile].dimensions.shape,
  testAreaDimensions: state.profiles[state.activeProfile].dimensions.testArea,
  redColour: state.profiles[state.activeProfile].colours.red,
  greenColour: state.profiles[state.activeProfile].colours.green,
  speed: state.profiles[state.activeProfile].speed,
  aspectRatio: state.profiles[state.activeProfile].aspectRatio,
  lineThickness: state.profiles[state.activeProfile].lineThickness,
  crossThickness: state.profiles[state.activeProfile].crossThickness,
  crossLength: state.profiles[state.activeProfile].crossLength,
});

const mapDispatchToProps = (dispatch, value) => {
  return {
    setRedColour: (value) => dispatch(setRedColour(value)),
    setGreenColour: (value) => dispatch(setGreenColour(value)),
    setSpeed: (value) => dispatch(setSpeed(value)),
    setAspectRatio: (value) => dispatch(setAspectRatio(value)),
    setTestAreaDimensions: (value) => dispatch(setTestAreaDimensions(value)),
    setShapeDimensions: (value) => dispatch(setShapeDimensions(value)),
    setLineThickness: (value) => dispatch(setLineThickness(value)),
    setCrossThickness: (value) => dispatch(setCrossThickness(value)),
    setCrossLength: (value) => dispatch(setCrossLength(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
