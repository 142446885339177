import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Label, FormText } from "reactstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { fullColourHex } from "../utils/Utils";
import ColourPicker from "../Components/ColourPicker/ColourPicker";

import "./TextSettings.css";

import {
  setTextWidth as setTextWidthRedux,
  setTextRedOpacity as setTextRedOpacityRedux,
  setTextGreenOpacity as setTextGreenOpacityRedux,
  setTextFontSize as setTextFontSizeRedux,
  setRedColour as setRedColourRedux,
  setGreenColour as setGreenColourRedux,
  setTextRedWordOpacity as setTextRedWordOpacityRedux,
  setTextGreenWordOpacity as setTextGreenWordOpacityRedux,
  setRedLuminance,
  setGreenLuminance,
} from "../actions/actions";

function TextTestDisplaySettings() {
  const textWidth = useSelector(
    (state) => state.profiles[state.activeProfile].text.width
  );
  const textRedOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.redOpacity
  );
  const textGreenOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.greenOpacity
  );
  const textFontSize = useSelector(
    (state) => state.profiles[state.activeProfile].text.fontSize
  );
  const textRedWordColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.red
  );
  const textGreenWordColour = useSelector(
    (state) => state.profiles[state.activeProfile].colours.green
  );
  const textRedWordOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.redWordOpacity
  );
  const textGreenWordOpacity = useSelector(
    (state) => state.profiles[state.activeProfile].text.greenWordOpacity
  );
  const redLuminance = useSelector(
    (state) => state.profiles[state.activeProfile].colours.redLuminance
  );
  const greenLuminance = useSelector(
    (state) => state.profiles[state.activeProfile].colours.greenLuminance
  );

  const dispatch = useDispatch();

  const setTextWidth = (value) => dispatch(setTextWidthRedux(value));
  const setTextRedOpacity = (value) => dispatch(setTextRedOpacityRedux(value));
  const setTextGreenOpacity = (value) =>
    dispatch(setTextGreenOpacityRedux(value));
  const setTextFontSize = (value) => dispatch(setTextFontSizeRedux(value));
  const setTextRedWordColour = (value) => dispatch(setRedColourRedux(value));
  const setTextGreenWordColour = (value) =>
    dispatch(setGreenColourRedux(value));
  const setTextRedWordOpacity = (value) =>
    dispatch(setTextRedWordOpacityRedux(value));
  const setTextGreenWordOpacity = (value) =>
    dispatch(setTextGreenWordOpacityRedux(value));
  const [pickerOn, setPickerOn] = useState("");
  const doSetRedLuminance = (value) => dispatch(setRedLuminance(value));
  const doSetGreenLuminance = (value) => dispatch(setGreenLuminance(value));
  const closeColourPicker = () => {
    console.log("Called out to close " + pickerOn);
    setPickerOn("");
  };
  return (
    <div className="textSettingsBox">
      <h4>Text display settings</h4>
      <Form>
        <FormGroup row>
          <Label
            xs={5}
            className="col-form-label col-xs-5"
            for="redColourPicker"
          >
            Red word colour
          </Label>
          &nbsp;
          <Col xs={2}>
            <div style={{ position: "absolute", zIndex: 10, right: 108 }}>
              <ColourPicker
                pickerOn={pickerOn === "red"}
                initialColour={textRedWordColour}
                setPickedColour={(colour, e) => {
                  setTextRedWordColour(colour.rgb);
                }}
                closeColourPicker={closeColourPicker}
              />
            </div>
            <Button
              id="redColourPicker"
              name="redColourPicker"
              onClick={() => {
                pickerOn === "red" ? setPickerOn("") : setPickerOn("red");
              }}
              className="pickerButton"
            >
              <div
                style={{
                  backgroundColor: fullColourHex(textRedWordColour),
                }}
              >
                &nbsp;
              </div>
            </Button>
          </Col>
          <Col xs={4}>
            <pre>
              R:{textRedWordColour.r} G:{textRedWordColour.g} B:
              {textRedWordColour.b}
            </pre>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label
            xs={5}
            className="col-form-label col-xs-5"
            htmlFor="greenColourPicker"
          >
            Green word colour
          </Label>
          &nbsp;
          <Col xs={2}>
            <div style={{ position: "absolute", zIndex: 10, right: 108 }}>
              <ColourPicker
                pickerOn={pickerOn === "green"}
                initialColour={textGreenWordColour}
                setPickedColour={(colour, e) => {
                  setTextGreenWordColour(colour.rgb);
                }}
                closeColourPicker={closeColourPicker}
              />
            </div>
            <Button
              id="greenColourPicker"
              name="greenColourPicker"
              onClick={() => {
                pickerOn === "green" ? setPickerOn("") : setPickerOn("green");
              }}
              className="pickerButton"
            >
              <div
                style={{
                  backgroundColor: fullColourHex(textGreenWordColour),
                }}
              >
                &nbsp;
              </div>
            </Button>
          </Col>
          <Col xs={4}>
            <pre>
              R:{textGreenWordColour.r} G:{textGreenWordColour.g} B:
              {textGreenWordColour.b}
            </pre>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={5} htmlFor="redluminanceInput">
            Red luminance
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="luminanceInput"
              name="luminanceInput"
              min={40}
              max={100}
              step={0.5}
              value={redLuminance}
              onChange={(e) => doSetRedLuminance(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={5} htmlFor="greenluminanceInput">
            Green luminance
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="greenluminanceInput"
              name="greenluminanceInput"
              min={40}
              max={100}
              step={0.5}
              value={greenLuminance}
              onChange={(e) => doSetGreenLuminance(e.target.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} htmlFor="textRedOpacityInput">
            Text area red bar opacity
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="textRedOpacityInput"
              name="textRedOpacityInput"
              min={0}
              max={1}
              step={0.01}
              value={textRedOpacity}
              onChange={(e) => setTextRedOpacity(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Opacity [{textRedOpacity}]</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} for="textGreenOpacityInput">
            Text area green bar opacity
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="textGreenOpacityInput"
              name="textGreenOpacityInput"
              min={0}
              max={1}
              step={0.01}
              value={textGreenOpacity}
              onChange={(e) => setTextGreenOpacity(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Opacity [{textGreenOpacity}]</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} for="textRedWordOpacity">
            Text area red word opacity
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="textRedWordOpacity"
              name="textRedWordOpacity"
              min={0}
              max={1}
              step={0.01}
              value={textRedWordOpacity}
              onChange={(e) => setTextRedWordOpacity(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Opacity [{textRedWordOpacity}]</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} for="textGreenWordOpacity">
            Text area green word opacity
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="textGreenWordOpacity"
              name="textGreenWordOpacity"
              min={0}
              max={1}
              step={0.01}
              value={textGreenWordOpacity}
              onChange={(e) => setTextGreenWordOpacity(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Opacity [{textGreenWordOpacity}]</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} for="textFontSizeInput">
            Text area font size
          </Label>
          <Col xs={4}>
            <RangeSlider
              id="textFontSizeInput"
              name="textFontSizeInput"
              min={0.9}
              max={2.2}
              step={0.02}
              value={textFontSize}
              onChange={(e) => setTextFontSize(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Size [{textFontSize}]</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={5} for="textWidthInput">
            Text area colour bar width
          </Label>
          <Col xs={4}>
            <RangeSlider
              min={1}
              max={300}
              step={1}
              id="textWidthInput"
              name="textWidthInput"
              value={textWidth}
              onChange={(e) => setTextWidth(e.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormText>Width [{textWidth}]</FormText>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

export default TextTestDisplaySettings;
