import React, { useState, useEffect, useCallback } from "react";
import * as PIXI from "pixi.js";
import bunnyTexture from "./assets/bunny.png";
import swatterTexture from "./assets/swatter.png";

export default function Game2(props) {
  const [pixiCnt, updatePixiCnt] = useState(null);
  //const updatePixiCnt = useRef(null);
  const [app, updateApp] = useState(null);
  const [swatter, setSwatter] = useState({ sprite: null });
  const [flies, setFlies] = useState([]);
  useEffect(() => {
    updateApp(
      new PIXI.Application({
        backgroundColor: 0xf8f8f8,
        options: {
          width: 800,
          height: 600,
          resolution: window.devicePixelRatio || 1,
        },
      })
    );
  }, []);

  useEffect(() => {
    //now we are adding the application to the DOM element which we got from the Ref.
    if (pixiCnt && pixiCnt.children.length <= 0) {
      pixiCnt.appendChild(app.view);
      //The setup function is a custom function that we created to add the sprites. We will this below
      setup();
    }
    // eslint-disable-next-line
  }, [pixiCnt]);

  const tick = (delta) => {
    /*   if (swatter.sprite) {
      const mouse = app.renderer.plugins.interaction.mouse.global;
      swatter.sprite.x = mouse.x;
      swatter.sprite.y = mouse.y;
    } */
    flies.forEach((fly) => {
      const sprite = fly.sprite;
      sprite.x += fly.speed * delta * Math.sin(sprite.rotation);
      sprite.y += fly.speed * delta * -Math.cos(sprite.rotation);
      if (sprite.x < fly.targetX) {
        fly.targetXspeed += 0.02 * delta;
      } else {
        fly.targetXspeed -= 0.02 * delta;
      }
      if (sprite.y < fly.targetY) {
        fly.targetYspeed += 0.02 * delta;
      } else {
        fly.targetYspeed -= 0.02 * delta;
      }
      sprite.x += fly.targetXspeed * delta;
      sprite.y += fly.targetYspeed * delta;
      sprite.rotation += fly.rotationSpeed * delta;
    });
  };

  const setup = () => {
    // Create a new texture
    const localContainer = new PIXI.Container();

    app.stage.addChild(localContainer);

    const swatterImage = PIXI.Texture.from(swatterTexture);
    const texture = PIXI.Texture.from(bunnyTexture);

    const swat = new PIXI.Sprite(swatterImage);
    swat.interactive = true;
    swat.dragging = true;
    swat.anchor.set(0.5);
    swat.x = 400;
    swat.y = 300;
    swat.anchor.set(0.5);
    swat.rotation = 0;
    swat.on("pointermove", (event) => {
      const { data, currentTarget } = event;
      const newPosition = data.getLocalPosition(currentTarget.parent);
      currentTarget.x = newPosition.x;
      currentTarget.y = newPosition.y;
    });
    localContainer.addChild(swat);
    swatter.sprite = swat;

    // Create a 5x5 grid of bunnies
    for (let i = 0; i < 25; i++) {
      const bunny = new PIXI.Sprite(texture);
      bunny.anchor.set(0.5);
      bunny.x = (i % 5) * 120;
      bunny.y = Math.floor(i / 5) * 120;
      bunny.rotation = Math.random() * 2 * Math.PI;
      localContainer.addChild(bunny);

      flies.push({
        sprite: bunny,
        speed: Math.random() * 2,
        rotationSpeed: Math.random() * 0.5 - 0.25,
        targetX: Math.random() * 700,
        targetY: Math.random() * 500,
        targetXspeed: 0,
        targetYspeed: 0,
      });
    }

    // Move container to the center
    localContainer.x = app.screen.width / 2;
    localContainer.y = app.screen.height / 2;

    // Center bunny sprite in local container coordinates
    localContainer.pivot.x = localContainer.width / 2;
    localContainer.pivot.y = localContainer.height / 2;

    // Listen for animate update
    app.ticker.add(tick);
  };

  const updatePixiCntMeta = useCallback(
    (element) => {
      // the element is the DOM object that we will use as container to add pixi stage(canvas)
      updatePixiCnt(element);
    },
    // eslint-disable-next-line
    []
  );

  return <div ref={updatePixiCntMeta} />;
}
