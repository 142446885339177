import React from "react";
import { connect } from "react-redux";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import {
  setNearFarFontSize,
  setNearFarFontFamily,
  setNearFarFontStretch,
  setNearFarFontStyle,
} from "../actions/actions";

import "./NearFarSettings.css";

const fontFamilies = [
  "Arial",
  "Comic Sans MS",
  "System",
  "Courier",
  "Times New Roman",
  "Verdana",
  "cursive",
  "serif",
  "sans-serif",
  "fantasy",
  "monospace",
];
const fontStretchVals = [
  "ultra-condensed",
  "extra-condensed",
  "condensed",
  "semi-condensed",
  "normal",
  "semi-expanded",
  "expanded",
  "extra-expanded",
  "ultra-expanded",
];

const fontStyles = ["normal", "italic", "oblique"];

function NearFarSettings(props) {
  const {
    fontSize,
    setFontSize,
    fontFamily,
    setFontFamily,
    fontStretch,
    setFontStretch,
    fontStyle,
    setFontStyle,
  } = props;
  return (
    <div className="profiles">
      <h4>Near far rock settings</h4>
      <Form>
        <FormGroup row>
          <Label for="fontSize" sm={4}>
            Font Size
          </Label>
          <Col sm={4}>
            <Input
              name="fontSize"
              id="fontSize"
              value={fontSize}
              placeholder="font size"
              onChange={(e) => setFontSize(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="fontFamily" sm={4}>
            Font Family
          </Label>
          <Col sm={4}>
            <Input
              type="select"
              name="fontFamily"
              id="fontFamily"
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
            >
              {fontFamilies.map((font) => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="fontStretch" sm={4}>
            Font Stretch
          </Label>
          <Col sm={4}>
            <Input
              type="select"
              name="fontStretch"
              id="fontStretch"
              value={fontStretch}
              placeholder="font stretch"
              onChange={(e) => setFontStretch(e.target.value)}
            >
              {fontStretchVals.map((font) => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="fontStyle" sm={4}>
            Font Style
          </Label>
          <Col sm={4}>
            <Input
              type="select"
              name="fontStyle"
              id="fontStyle"
              value={fontStyle}
              placeholder="font style"
              onChange={(e) => setFontStyle(e.target.value)}
            >
              {fontStyles.map((font) => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}
const mapStateToProps = (state) => ({
  fontSize: state.profiles[state.activeProfile].nearFarRock.fontSize,
  fontFamily: state.profiles[state.activeProfile].nearFarRock.fontFamily,
  fontStretch: state.profiles[state.activeProfile].nearFarRock.fontStretch,
  fontStyle: state.profiles[state.activeProfile].nearFarRock.fontStyle,
});
const mapDispatchToProps = (dispatch, value) => ({
  setFontSize: (value) => dispatch(setNearFarFontSize(value)),
  setFontFamily: (value) => dispatch(setNearFarFontFamily(value)),
  setFontStretch: (value) => dispatch(setNearFarFontStretch(value)),
  setFontStyle: (value) => dispatch(setNearFarFontStyle(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NearFarSettings);
